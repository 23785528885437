import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivities,
  userGettingActivityActions,
} from "../../../redux/User/userGettingActivitySlice";

const ActivityTablePagination = ({ pageNumber, totalPage }) => {
  const dispatch = useDispatch();
  const filteredData = useSelector(
    (state) => state.userGettingActivity.filteredData
  );

  return (
    <Stack spacing={2} style={{ marginTop: "3vh" }}>
      <Pagination
        count={totalPage}
        color="primary"
        page={pageNumber}
        onChange={(event, page) => {
          if (page !== pageNumber) {
            dispatch(userGettingActivityActions.setPageNumber(page));
            dispatch(getActivities({ filteredData: filteredData, page: page }));
          }
        }}
      />
    </Stack>
  );
};

export default ActivityTablePagination;
