import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivitiesCalculator,
  userActivityCalorieCalculatorActions,
} from "../../../redux/User/userActivityCalorieCalculatorSlice";

export default function ActivityCalculatorPagination({
  pageNumber,
  totalPage,
}) {
  const dispatch = useDispatch();
  const filteredData = useSelector(
    (state) => state.userActivityCalculator.filteredData
  );

  return (
    <Stack spacing={2} style={{ marginTop: "3vh" }}>
      <Pagination
        count={totalPage}
        color="primary"
        page={pageNumber}
        onChange={(event, page) => {
          if (page !== pageNumber) {
            dispatch(userActivityCalorieCalculatorActions.setPageNumber(page));
            dispatch(
              getActivitiesCalculator({ filteredData: filteredData, page: page })
            );
          }
        }}
      />
    </Stack>
  );
}
