import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  getFoodsCalculator,
  userFoodCalorieCalculatorActions,
} from "../../../redux/User/userFoodCalorieCalculatorSlice";

export default function FoodCalculatorPagination({ pageNumber, totalPage }) {
  const dispatch = useDispatch();
  const filteredData = useSelector(
    (state) => state.userFoodCalculator.filteredData
  );

  return (
    <Stack spacing={2} style={{ marginTop: "3vh" }}>
      <Pagination
        count={totalPage}
        color="primary"
        page={pageNumber}
        onChange={(event, page) => {
          if (page !== pageNumber) {
            dispatch(userFoodCalorieCalculatorActions.setPageNumber(page));
            dispatch(
              getFoodsCalculator({ filteredData: filteredData, page: page })
            );
          }
        }}
      />
    </Stack>
  );
}
