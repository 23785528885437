import ResizableBox from "./ResizableBox";
import useDemoConfig from "./useDemoConfig";
import React from "react";
import { Chart } from "react-charts";
import { makeSeries } from "./useDemoConfig";
import { useSelector } from "react-redux";

export default function Graph({ calorieData }) {
  const token = useSelector(state => state.signIn.token)

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
      },
    ],
    []
  );

  const calorieInformation = token ? calorieData : []

  const calorieGraph = makeSeries(
    0,
    "time",
    calorieInformation.length,
    true,
    calorieInformation
  );

  return (
    <>
      <ResizableBox>
        <Chart
          options={{
            data: [calorieGraph],
            primaryAxis,
            secondaryAxes,
          }}
        />
      </ResizableBox>
    </>
  );
}
