import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addingFoodRecord,
  userAddingFoodActions,
} from "../../../../redux/User/userAddingFoodSlice";
import { getUserGraphs } from "../../../../redux/User/userCalorieGraphSlice";
import { getUserCalorieInfo } from "../../../../redux/User/userCalorieInfoSlice";
import {
  getFoods,
  userGettingFoodActions,
} from "../../../../redux/User/userGettingFoodSlice";
import TablePagination from "../../TablePagination/TablePagination";
import styles from "./FoodAddingTable.module.css";
import searchIcon from "../../../../assets/images/Pages/ProfilePage/search.png";
import { toast } from "react-toastify";
import { getUserGoal } from "../../../../redux/User/userAddGoalSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "39vw",
  height: "70vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "4.5vh 2vw",
  borderRadius: "1.85vmin",
};

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 180,
    sortable: false,
    filterable: false,
  },
  {
    field: "calories",
    headerName: "Calories ( per 100gr )",
    width: 160,
    sortable: false,
    filterable: false,
  },
  {
    field: "foodCategoryDto",
    headerName: "Category",
    width: 170,
    sortable: false,
    filterable: false,
    valueGetter: (params) => params.row?.foodCategoryDto?.name,
  },
];

export default function FoodAddingTable() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.userAddingFood.isOpen);
  const token = useSelector((state) => state.signIn.token);
  const filteredData = useSelector(
    (state) => state.userGettingFood.filteredData
  );
  const pageNumber = useSelector((state) => state.userGettingFood.pageNumber);
  const totalPage = useSelector((state) => state.userGettingFood.totalPage);
  const foodCategories = useSelector(
    (state) => state.userGettingFood.foodCategories
  );
  const foods = useSelector((state) => state.userGettingFood.foods);
  const category = useSelector(
    (state) => state.userGettingFood.filteredData.category
  );
  const foodRecord = useSelector((state) => state.userAddingFood.foodRecord);
  const addingStatus = useSelector((state) => state.userAddingFood.status);

  const mass = useSelector((state) => state.userAddingFood.foodRecord.mass);
  const id = useSelector((state) => state.userAddingFood.foodRecord.food.id);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.bodyWithModalOpen);
    } else {
      document.body.classList.remove(styles.bodyWithModalOpen);
    }

    return () => {
      document.body.classList.remove(styles.bodyWithModalOpen);
    };
  }, [isOpen]);

  const addButtonHandler = (event) => {
    event.preventDefault();
    if (mass && id && mass > 0) {
      dispatch(addingFoodRecord(foodRecord));
      dispatch(userAddingFoodActions.setRefresh());
      dispatch(getUserCalorieInfo());
      dispatch(getUserGraphs());
      dispatch(userAddingFoodActions.setFoodRecordNull());
      dispatch(getUserGoal());
    } else {
      toast.error(
        "You must fill the amount area and choose a food from the table. You can't enter negative value.",
        {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={() => {
        dispatch(userAddingFoodActions.setIsOpen());
        dispatch(userAddingFoodActions.setFoodRecordNull());
        dispatch(userGettingFoodActions.setFilteredDataNull());
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className={styles.main}>
        <Fade in={isOpen}>
          {addingStatus !== "pending" ? (
            <Box sx={style}>
              <Box component="form" autoComplete="off">
                <div
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  className={styles.filterPart}
                >
                  <Autocomplete
                    getOptionLabel={(option) => option.name}
                    clearOnEscape
                    options={foodCategories}
                    size="small"
                    value={category}
                    renderInput={(params) => (
                      <TextField {...params} label="All Categories" />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        dispatch(userGettingFoodActions.setCategory(value));
                        dispatch(
                          userGettingFoodActions.setFoodCategoryName(value.name)
                        );
                      } else {
                        dispatch(userGettingFoodActions.setCategory(null));
                        dispatch(
                          userGettingFoodActions.setFoodCategoryName("")
                        );
                      }
                    }}
                    className={styles.categories}
                  />
                  <TextField
                    label="Name"
                    size="small"
                    onChange={(e) =>
                      dispatch(userGettingFoodActions.setName(e.target.value))
                    }
                    className={styles.name}
                  />
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      dispatch(
                        getFoods({ filteredData: filteredData, page: 1 })
                      );
                      dispatch(userGettingFoodActions.setPageNumber(1));
                    }}
                    className={styles.filterButton}
                    type="submit"
                  >
                    <div>FIND</div>
                    <img src={searchIcon} />
                  </button>
                </div>
              </Box>
              <Box
                component="form"
                autoComplete="off"
                className={styles.calculateContainer}
              >
                <div id="transition-modal-description">
                  <DataGrid
                    rows={foods}
                    columns={columns}
                    hideFooter
                    style={{
                      maxHeight: `${39.6}vh`,
                      minHeight: `${39.6}vh`,
                      width: `${35.2}vw`,
                      marginTop: `${1}vw`,
                      fontSize: "1.94625vmin",
                    }}
                    onRowClick={(params) => {
                      dispatch(
                        userAddingFoodActions.setAddingFoodId(params.row.id)
                      );
                    }}
                  />
                  <TablePagination
                    pageNumber={pageNumber}
                    totalPage={totalPage}
                  />
                </div>
                <div
                  variant="h6"
                  component="h2"
                  className={styles.addPart}
                >
                  <TextField
                    label="Amount (gr)"
                    size="small"
                    onChange={(e) =>
                      dispatch(
                        userAddingFoodActions.setAddingFoodMass(e.target.value)
                      )
                    }
                    className={styles.amount}
                  />
                  <button
                    onClick={addButtonHandler}
                    className={styles.addButton}
                    type="submit"
                  >
                    Add
                  </button>
                </div>
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={style}
            >
              <CircularProgress />
            </Box>
          )}
        </Fade>
      </div>
    </Modal>
  );
}
