import ForgotPasswordSecondPageForm from "../../../components/Forms/ForgotPasswordForm/ForgotPasswordSecondPageForm";
import Logo from "../../../components/Logo/Logo";
import styles from "./ForgotPasswordSecondPage.module.css";

export default function ForgotPasswordSecondPage() {
  return (
    <main className={styles.main}>
      <div className={styles.logo}>
        <Logo width={9} />
      </div>
      <div className={styles.backgroundSignUpVerifyForm}>
        <ForgotPasswordSecondPageForm />
      </div>
    </main>
  );
}
