import React, { useState, useRef } from 'react';
import styles from './TruncatedTextWithTooltip.module.css';

function TruncatedTextWithTooltip({ text }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: -50, left: 0 });
  const textRef = useRef(null);

  const handleMouseOver = (event) => {
    if (textRef.current) {
      const { top, left } = textRef.current.getBoundingClientRect();
      setTooltipPosition({ top, left });
      setShowTooltip(true);
    }
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };

  return (
    <div className={styles.truncateText} 
         ref={textRef} 
         onMouseOver={handleMouseOver} 
         onMouseOut={handleMouseOut}>
      {text}
      {showTooltip && (
        <div 
          className={styles.tooltip} 
          style={{ top: tooltipPosition.top - 5, left: tooltipPosition.left }}>
          {text}
        </div>
      )}
    </div>
  );
}

export default TruncatedTextWithTooltip;
