import { Box, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signUpActions } from "../../../redux/SignUp/signUpSlice";

import { toast } from "react-toastify";

import openEye from "../../../assets/images/Pages/SignPages/openEye.png";
import closeEye from "../../../assets/images/Pages/SignPages/closeEye.png";

import styles from "./SignUpFirstForm.module.css";

export default function SignUpFirstForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const name = useSelector((state) => state.signUp.userInformation.name);
  const surname = useSelector((state) => state.signUp.userInformation.surname);
  const email = useSelector((state) => state.signUp.userInformation.email);
  const password = useSelector(
    (state) => state.signUp.userInformation.password
  );
  const eyeIsClicked = useSelector((state) => state.signUp.eyeIsClicked);

  const sendSignUpInformation = (event) => {
    event.preventDefault();

    if (
      name.trim() !== "" &&
      surname.trim() !== "" &&
      email.trim() !== "" &&
      password.trim() !== ""
    ) {
      navigate("/signUpSecondPage");
    } else {
      toast.error("Please fill in all required fields to continue.", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch", background: "white" },
      }}
      noValidate
      autoComplete="off"
      className={styles.form}
    >
      <div className={styles.nameSurname}>
        <div className={styles.nameInput}>
          <InputLabel
            style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }}
            htmlFor="name"
          >
            Name
          </InputLabel>
          <TextField
            style={{ width: `${7.2}vw` }}
            id="name"
            name="name"
            size="small"
            type="text"
            onChange={(e) => dispatch(signUpActions.setName(e.target.value))}
            autoFocus
          />
        </div>

        <div className={styles.surnameInput}>
          <InputLabel
            style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }}
            htmlFor="surname"
          >
            Surname
          </InputLabel>
          <TextField
            style={{ width: `${7.2}vw` }}
            id="surname"
            name="surname"
            size="small"
            type="text"
            onChange={(e) => dispatch(signUpActions.setSurname(e.target.value))}
          />
        </div>
      </div>

      <div>
        <InputLabel style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }} htmlFor="email">
          Email Address
        </InputLabel>
        <TextField
          style={{ width: `${15.5}vw` }}
          id="email"
          name="email"
          size="small"
          type="email"
          onChange={(e) => dispatch(signUpActions.setEmail(e.target.value))}
        />
      </div>

      <div>
        <InputLabel
          style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }}
          htmlFor="password"
        >
          Password
        </InputLabel>
        <TextField
          style={{ width: `${15.5}vw`, backgroundColor: "#E8F0FE" }}
          id="password"
          name="password"
          size="small"
          type={eyeIsClicked ? "text" : "password"}
          onChange={(e) => dispatch(signUpActions.setPassword(e.target.value))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img
                  src={eyeIsClicked ? closeEye : openEye}
                  className={styles.passwordEye}
                  onClick={() => {
                    dispatch(signUpActions.setEyeIsClicked());
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <button
        className={styles.btn_signUp}
        type="submit"
        onClick={sendSignUpInformation}
      >
        CONTINUE
      </button>
    </Box>
  );
}
