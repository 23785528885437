import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import searchIcon from "../../../../assets/images/Pages/ProfilePage/search.png";
import {
  activityCalculator,
  getActivitiesCalculator,
  userActivityCalorieCalculatorActions,
} from "../../../../redux/User/userActivityCalorieCalculatorSlice";

import styles from "./ActivityCalculatorTable.module.css";
import ActivityCalculatorPagination from "../../TablePagination/ActivityCalculatorPagination";
import { toast } from "react-toastify";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 240,
    sortable: false,
    filterable: false,
  },
  {
    field: "activityCategoryDto",
    headerName: "Category",
    width: 240,
    sortable: false,
    filterable: false,
    valueGetter: (params) => params.row?.activityCategoryDto?.name,
  },
];

export default function ActivityCalculatorTable() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.signIn.token);
  const filteredData = useSelector(
    (state) => state.userActivityCalculator.filteredData
  );
  const pageNumber = useSelector(
    (state) => state.userActivityCalculator.pageNumber
  );
  const totalPage = useSelector(
    (state) => state.userActivityCalculator.totalPage
  );
  const activityCategories = useSelector(
    (state) => state.userActivityCalculator.activityCategories
  );
  const activities = useSelector(
    (state) => state.userActivityCalculator.activities
  );
  const category = useSelector(
    (state) => state.userActivityCalculator.filteredData.category
  );
  const activityRecord = useSelector(
    (state) => state.userActivityCalculator.activityRecord
  );

  const calculationResult = useSelector(
    (state) => state.userActivityCalculator.calculationResult
  );

  const id = useSelector(
    (state) => state.userActivityCalculator.activityRecord.activityId
  );
  const duration = useSelector(
    (state) => state.userActivityCalculator.activityRecord.duration
  );
  const heartRate = useSelector(
    (state) => state.userActivityCalculator.activityRecord.heartRate
  );
  const bodyTemp = useSelector(
    (state) => state.userActivityCalculator.activityRecord.bodyTemp
  );

  const calculateHandler = (event) => {
    event.preventDefault();
    if (duration && id && duration > 0 && heartRate > 0 && bodyTemp > 0) {
      dispatch(activityCalculator(activityRecord));
      dispatch(userActivityCalorieCalculatorActions.setRefresh());
    } else {
      toast.error(
        "You must fill all areas and choose an activity from the table. You can't enter negative values.",
        {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  return (
    <Box className={styles.main}>
      <Box component="form" autoComplete="off">
        <div
          id="transition-modal-title"
          variant="h6"
          component="h2"
          className={styles.filterPart}
        >
          <Autocomplete
            getOptionLabel={(option) => option.name}
            clearOnEscape
            options={activityCategories}
            size="small"
            value={category}
            renderInput={(params) => (
              <TextField {...params} label="All Categories" />
            )}
            onChange={(event, value) => {
              if (value) {
                dispatch(
                  userActivityCalorieCalculatorActions.setCategory(value)
                );
                dispatch(
                  userActivityCalorieCalculatorActions.setActivityCategoryName(
                    value.name
                  )
                );
              } else {
                dispatch(
                  userActivityCalorieCalculatorActions.setCategory(null)
                );
                dispatch(
                  userActivityCalorieCalculatorActions.setActivityCategoryName(
                    ""
                  )
                );
              }
            }}
            className={styles.categories}
          />
          <TextField
            label="Name"
            size="small"
            onChange={(e) =>
              dispatch(
                userActivityCalorieCalculatorActions.setName(e.target.value)
              )
            }
            className={styles.name}
          />
          <button
            onClick={(event) => {
              event.preventDefault();
              dispatch(
                getActivitiesCalculator({ filteredData: filteredData, page: 1 })
              );
              dispatch(userActivityCalorieCalculatorActions.setPageNumber(1));
            }}
            className={styles.filterButton}
            type="submit"
          >
            <div>FIND</div>
            <img src={searchIcon} />
          </button>
        </div>
      </Box>
      <Box
        component="form"
        autoComplete="off"
        className={styles.calculateContainer}
      >
        <div id="transition-modal-description" sx={{ mt: 2 }}>
          <DataGrid
            rows={activities}
            columns={columns}
            hideFooter
            style={{
              maxHeight: `${39.6}vh`,
              minHeight: `${39.6}vh`,
              width: `${35.2}vw`,
              marginTop: `${1.47}vw`,
              fontSize: "1.94625vmin",
            }}
            onRowClick={(params) => {
              dispatch(
                userActivityCalorieCalculatorActions.setActivityCalculatorId(
                  params.row.id
                )
              );
              dispatch(
                userActivityCalorieCalculatorActions.setActivityCalculatorHeartRate(
                  params.row.heartBeat
                )
              );
              dispatch(
                userActivityCalorieCalculatorActions.setActivityCalculatorBodyTemp(
                  params.row.bodyTemp
                )
              );
            }}
          />
          <ActivityCalculatorPagination
            pageNumber={pageNumber}
            totalPage={totalPage}
          />
        </div>
        <div
          variant="h6"
          component="h2"
          sx={{ mt: 2 }}
          className={styles.addPart}
        >
          <TextField
            label="Duration (min)"
            size="small"
            onChange={(e) =>
              dispatch(
                userActivityCalorieCalculatorActions.setActivityCalculatorDuration(
                  e.target.value
                )
              )
            }
            className={styles.duration}
          />
          <TextField
            value={activityRecord.heartRate ? activityRecord.heartRate : 0}
            label="Heart Rate"
            size="small"
            onChange={(e) =>
              dispatch(
                userActivityCalorieCalculatorActions.setActivityCalculatorHeartRate(
                  e.target.value
                )
              )
            }
            className={styles.heartRate}
          />
          <TextField
            label="Body Temperature"
            value={activityRecord.bodyTemp ? activityRecord.bodyTemp : 0}
            size="small"
            onChange={(e) =>
              dispatch(
                userActivityCalorieCalculatorActions.setActivityCalculatorBodyTemp(
                  e.target.value
                )
              )
            }
            className={styles.bodyTemp}
          />
          <button
            onClick={calculateHandler}
            className={styles.calculateButton}
            type="submit"
          >
            Calculate
          </button>
        </div>
      </Box>

      <div className={styles.calculationResult}>
        {calculationResult} Calories
      </div>
    </Box>
  );
}
