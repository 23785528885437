import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeaderboard,
  userAddGoalActions,
} from "../../../redux/User/userAddGoalSlice";

export default function LeaderboardPagination({ pageNumber, totalPage }) {
  const dispatch = useDispatch();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <Stack spacing={2} style={{ marginTop: "3vh" }}>
      <Pagination
        count={totalPage}
        color="primary"
        page={pageNumber}
        onChange={(event, page) => {
          if (page !== pageNumber) {
            dispatch(userAddGoalActions.setPageNumber(page));
            dispatch(getLeaderboard(page));
            scrollToTop();
          }
        }}
      />
    </Stack>
  );
}
