import styles from "./Logo.module.css";
import logo from "../../assets/images/Logos/Logo.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Logo(props) {
  const token = useSelector((state) => state.signIn.token);

  return (
    <div className="logo">
      <Link to={token ? "/profile" : "/"}>
        <img
          src={logo}
          alt="Logo"
          className={styles.image}
          style={{ width: `${props.width}vw` }}
        />
      </Link>
    </div>
  );
}
