import ProfileNavbar from "../../components/Profile/Navbar/ProfileNavbar";
import ProfileAside from "../../components/Profile/ProfileAside/ProfileAside";
import ProfileMain from "../../components/Profile/ProfileMain/ProfileMain";
import styles from "./Profile.module.css";

export default function Profile() {
  return (
    <div className={styles.profile}>
      <ProfileAside />
      <div className={styles.rightPart}>
        <ProfileNavbar />
        <ProfileMain />
      </div>
    </div>
  );
}