import SignUpFirstForm from "../../components/Forms/SignUpForm/SignUpFirstForm";
import Logo from "../../components/Logo/Logo";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import styles from "./SignUpFirstPage.module.css";
import { Link } from "react-router-dom";

export default function SignUpFirstPage() {
  return (
    <main className={styles.main}>
      <div className={styles.logo}>
        <Logo width={9} />
      </div>
      <div className={styles.backgroundSignUpForm}>
        <SignUpFirstForm />
        <ProgressBar whichPage="First" secondBgColor="#d9d9d9" />
      </div>
      <footer className={styles.footer}>
        <div>Already Have an Account?</div>
        <Link to="/signIn" className={styles.signIn}>
          Sign In
        </Link>
      </footer>
    </main>
  );
}
