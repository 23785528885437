import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserGraphs } from "../../../redux/User/userCalorieGraphSlice";
import { getUserCalorieInfo } from "../../../redux/User/userCalorieInfoSlice";
import { getHistory } from "../../../redux/User/userCalorieHistorySlice";

import BalanceCalorieCard from "../Cards/CalorieInfo/BalanceCalorieCard/BalanceCalorieCard";
import BurnedCalorieCard from "../Cards/CalorieInfo/BurnedCalorieCard/BurnedCalorieCard";
import ConsumedCalorieCard from "../Cards/CalorieInfo/ConsumedCalorieCard/ConsumedCalorieCard";
import TodayCard from "../Cards/TodayCard/TodayCard";
import ProfilePredictorPage from "../../../pages/ProfilePredictorPage/ProfilePredictorPage";
import ProfileLeaderboard from "../ProfileLeaderboard/ProfileLeaderboard";
import ProfileGoal from "../ProfileGoal/ProfileGoal";

import GraphSection from "../GraphSection/GraphSection";
import ActivityTable from "../UserTables/ActivityTable/ActivityTable";
import FoodTable from "../UserTables/FoodTable/FoodTable";
import styles from "./ProfileMain.module.css";
import { getUserInfo } from "../../../redux/User/userInfoSlice";
import ProfileHistoryPage from "../../../pages/ProfileHistoryPage/ProfileHistoryPage";
import ProfileCalorieCalculatorPage from "../../../pages/ProfileCalorieCalculatorPage/ProfileCalorieCalculatorPage";
import {
  getUserGoal,
  userAddGoalActions,
} from "../../../redux/User/userAddGoalSlice";

export default function ProfileMain() {
  const dispatch = useDispatch();

  const [scrolled, setScrolled] = useState(false);

  const userCalorieInformation = useSelector(
    (state) => state.userCalorieInfo.userCalorieInformation
  );
  const userGraphs = useSelector((state) => state.userCalorieGraph.userGraphs);
  let refreshFood = useSelector((state) => state.userAddingFood.refresh);
  let refreshActivity = useSelector(
    (state) => state.userAddingActivity.refresh
  );
  let refreshAddGoal = useSelector((state) => state.userAddGoal.refresh);

  const token = useSelector((state) => state.signIn.token);
  const activeButton = useSelector((state) => state.userTools.activeButton);

  const filteredData = useSelector(
    (state) => state.userCalorieHistory.filteredData
  );
  const nullFilteredData = useSelector(
    (state) => state.userCalorieHistory.nullFilteredData
  );

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (token && (refreshFood || refreshActivity || refreshAddGoal)) {
      dispatch(getUserInfo());
      dispatch(getUserCalorieInfo());
      dispatch(getUserGraphs());
      dispatch(getHistory({ filteredData: nullFilteredData, page: 1 }));
      dispatch(getUserGoal());
    }
  }, [token, refreshFood, refreshActivity, refreshAddGoal]);

  return (
    <main className={styles.main}>
      <div className={styles.mainLeft}>
        {activeButton === "Panel" && token && (
          <div className={styles.panel}>
            <div className={styles.mainContainer}>
              <BurnedCalorieCard
                calorie={token && userCalorieInformation.totalBurnedCalories}
              />
              <ConsumedCalorieCard
                calorie={token && userCalorieInformation.totalConsumedCalories}
              />
              <BalanceCalorieCard
                calorie={token && userCalorieInformation.balance}
              />
            </div>
            <div className={styles.goalContainer}>
              <div className={styles.topTable}>
                <div className={styles.tablesTitle}>Daily Goal</div>
                <div className={styles.divider}></div>
              </div>
              <div>
                <ProfileGoal />
              </div>
            </div>
            <div className={styles.tables}>
              <div className={styles.topTable}>
                <div className={styles.tablesTitle}>Today's Record</div>
                <div className={styles.divider}></div>
              </div>
              <div className={styles.table}>
                <div>
                  <FoodTable />
                </div>
                <div>
                  <ActivityTable />
                </div>
              </div>
            </div>
            <GraphSection userGraphs={userGraphs} />
          </div>
        )}
        {activeButton === "Calculator" && token && (
          <ProfileCalorieCalculatorPage />
        )}
        {activeButton === "Predictor" && token && <ProfilePredictorPage />}
        {activeButton === "History" && token && <ProfileHistoryPage />}
        {activeButton === "Leaderboard" && token && <ProfileLeaderboard />}
      </div>
      <div className={styles.mainRight}>
        <TodayCard />
        {scrolled && (
          <div
            className={styles.upButton}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <KeyboardArrowUpIcon />
          </div>
        )}
        <div className={styles.indicator}>Test Yayını</div>
      </div>
    </main>
  );
}
