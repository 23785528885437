import NavigationBar from "../../components/Introduction/NavigationBar/NavigationBar";
import IntroductionPartUp from "../../components/Introduction/IntroductionPartUp/IntroductionPartUp";
import IntroductionPartDown from "../../components/Introduction/IntroductionPartDown/IntroductionPartDown";

import styles from "./IntroductionPage.module.css";
import Footer from "../../components/Introduction/Footer/Footer";

export default function IntroductionPage() {
  return (
    <div className={styles.introductionMain}>
      <NavigationBar />
      <div className={styles.parts}>
        <IntroductionPartUp />
        <IntroductionPartDown />
        <Footer />
      </div>
    </div>
  );
}
