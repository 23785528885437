import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
    getHistory,
    userCalorieHistoryActions,
} from "../../../redux/User/userCalorieHistorySlice";

const UserCalorieHistoryPagination = ({ pageNumber, totalPage }) => {
  const dispatch = useDispatch();
  const filteredData = useSelector(
    (state) => state.userCalorieHistory.filteredData
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth' 
    });
  };

  return (
    <Stack spacing={2} style={{ marginTop: "3vh" }}>
      <Pagination
        count={totalPage}
        color="primary"
        page={pageNumber}
        onChange={(event, page) => {
          if (page !== pageNumber) {
            dispatch(userCalorieHistoryActions.setPageNumber(page));
            dispatch(getHistory({ filteredData: filteredData, page: page }));
          }
        }}
        onClick={scrollToTop}
      />
    </Stack>
  );
};

export default UserCalorieHistoryPagination;
