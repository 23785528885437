import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { userToolsActions } from "../../../redux/User/userToolsSlice";
import styles from "./Buttons.module.css";
import {
  getFoodsCalculator,
  getFoodCategoriesCalculator,
} from "../../../redux/User/userFoodCalorieCalculatorSlice";
import {
  getActivitiesCalculator,
  getActivityCategoriesCalculator,
} from "../../../redux/User/userActivityCalorieCalculatorSlice";
import { predictCalories } from "../../../redux/User/userCaloriePredictorSlice";
import {
  getGoalUserCalorieInfo,
  getGoalUserInfo,
  getLeaderboard,
  getUserGoal,
} from "../../../redux/User/userAddGoalSlice";

export default function Buttons() {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.signIn.token);
  const activeButton = useSelector((state) => state.userTools.activeButton);
  const enabledTool = useSelector((state) => state.userTools.enabledTool);
  const filteredFoodData = useSelector(
    (state) => state.userFoodCalculator.filteredData
  );
  const filteredActivityData = useSelector(
    (state) => state.userActivityCalculator.filteredData
  );

  const page = useSelector((state) => state.userAddGoal.pageNumber);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className={styles.buttons}>
      <button
        className={`${styles.btn} ${styles.panelButton} ${
          activeButton === "Panel" && token && styles.active
        }`}
        onClick={() => {
          dispatch(userToolsActions.setActiveButton("Panel"));
          dispatch(getGoalUserInfo());
          dispatch(getGoalUserCalorieInfo())
          scrollToTop();
        }}
      >
        Panel
      </button>
      <button
        className={`${styles.btn} ${styles.toolButton}`}
        onClick={() => {
          dispatch(userToolsActions.setEnabledTool());
        }}
      >
        Tools{" "}
        {enabledTool && token ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </button>
      {enabledTool && token && (
        <div className={styles.tools}>
          <button
            className={`${styles.btn} ${styles.calculator} ${
              activeButton === "Calculator" && token && styles.active
            }`}
            onClick={() => {
              dispatch(userToolsActions.setActiveButton("Calculator"));
              dispatch(
                getFoodsCalculator({ filteredData: filteredFoodData, page: 1 })
              );
              dispatch(getFoodCategoriesCalculator());

              dispatch(
                getActivitiesCalculator({
                  filteredData: filteredActivityData,
                  page: 1,
                })
              );
              dispatch(getActivityCategoriesCalculator());
              dispatch(getUserGoal());
              scrollToTop();
            }}
          >
            Calories Calculator
          </button>
          <button
            className={`${styles.btn} ${styles.predictor} ${
              activeButton === "Predictor" && token && styles.active
            }`}
            onClick={() => {
              dispatch(userToolsActions.setActiveButton("Predictor"));
              dispatch(predictCalories());
              scrollToTop();
            }}
          >
            Calories Predictor
          </button>
        </div>
      )}
      <button
        className={`${styles.btn} ${styles.leaderboardButton} ${
          activeButton === "Leaderboard" && token && styles.active
        }`}
        onClick={() => {
          dispatch(userToolsActions.setActiveButton("Leaderboard"));
          dispatch(getLeaderboard(page));
          scrollToTop();
        }}
      >
        Leaderboard
      </button>
      <button
        className={`${styles.btn} ${styles.historyButton} ${
          activeButton === "History" && token && styles.active
        }`}
        onClick={() => {
          dispatch(userToolsActions.setActiveButton("History"));
          scrollToTop();
        }}
      >
        History
      </button>
    </div>
  );
}
