import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import IntroductionPage from "../pages/IntroductionPage/IntroductionPage";
import { getToken } from "../utils/getToken";

const RootPathRedirect = () => {
  const token = useSelector((state) => state.signIn.token) || getToken();

  if (token) {
    return <Navigate to="/profile" replace />;
  }

  return <IntroductionPage />;
};

export default RootPathRedirect;
