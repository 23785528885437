import SignUpVerifyForm from "../../components/Forms/SignUpForm/SignUpVerifyForm";
import Logo from "../../components/Logo/Logo";
import styles from "./SignUpVerifyPage.module.css";

export default function SignUpVerifyPage() {
  return (
    <main className={styles.main}>
      <div className={styles.logo}>
        <Logo width={9} />
      </div>
      <div className={styles.backgroundSignUpVerifyForm}>
        <SignUpVerifyForm />
      </div>
    </main>
  );
}
