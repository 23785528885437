import { BrowserRouter, Route, Routes } from "react-router-dom";

import { store } from "./store/index";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SignInPage from "./pages/SignInPage/SignInPage";
import SignUpFirstPage from "./pages/SignUpPage/SignUpFirstPage";
import SignUpSecondPage from "./pages/SignUpPage/SignUpSecondPage";
import SignUpVerifyPage from "./pages/SignUpPage/SignUpVerifyPage";
import Profile from "./pages/ProfilePage/Profile";
import { Provider } from "react-redux";

import ProtectedRoute from "./route/ProtectedRoute";

import "./styles/global/main.css";
import RootPathRedirect from "./route/RootPathRedirect";
import ForgotPasswordFirstPage from "./pages/ForgotPasswordPages/ForgotPasswordFirstPage/ForgotPasswordFirstPage";
import ForgotPasswordSecondPage from "./pages/ForgotPasswordPages/ForgotPasswordSecondPage/ForgotPasswordSecondPage";

export default function App() {
  return (
    <Provider store={store}>
      <div className="appBody">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RootPathRedirect />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/profile" element={<Profile />} />
            </Route>
            <Route path="/signIn" element={<SignInPage />} />
            <Route path="/signUpFirstPage" element={<SignUpFirstPage />} />
            <Route path="/signUpSecondPage" element={<SignUpSecondPage />} />
            <Route path="/signUpVerifyPage" element={<SignUpVerifyPage />} />
            <Route path="/forgotPasswordFirstPage" element={<ForgotPasswordFirstPage />} />
            <Route path="/forgotPasswordSecondPage" element={<ForgotPasswordSecondPage />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </div>
    </Provider>
  );
}
