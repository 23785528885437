import { useSelector } from "react-redux";
import HistoryFilter from "../../components/Profile/ProfileHistory/HistoryFilter/HistoryFilter";
import HistoryList from "../../components/Profile/ProfileHistory/HistoryList/HistoryList";
import styles from "./ProfileHistoryPage.module.css";

export default function ProfileHistoryPage() {
  return (
    <main>
      <div className={styles.topHistory}>
        <div className={styles.titleAndFilter}>
          <div className={styles.historiesTitle}>Records History</div>
          <HistoryFilter />
        </div>
        <div className={styles.divider}></div>
      </div>
      <HistoryList />
    </main>
  );
}
