import { Leaderboard } from "@mui/icons-material";

import boyAvatar from "../../../assets/images/Pages/ProfilePage/boyAvatar.png";
import girlAvatar from "../../../assets/images/Pages/ProfilePage/girlAvatar.png";

import styles from "./ProfileLeaderboard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LeaderboardPagination from "../TablePagination/LeaderboardPagination";

const DUMMY_DATA = [
  { id: 1, rank: 1, name: "Yunus Emre", surname: "Pak", score: 100, gender: 0 },
  { id: 2, rank: 2, name: "Mehmet", surname: "Subasi", score: 90, gender: 0 },
  {
    id: 3,
    rank: 3,
    name: "Muhammet Berkay",
    surname: "Garip",
    score: 80,
    gender: 0,
  },
  {
    id: 4,
    rank: 4,
    name: "Melisa Merve",
    surname: "Onur",
    score: 70,
    gender: 1,
  },
  {
    id: 5,
    rank: 5,
    name: "Ahmet Berkan",
    surname: "Garip",
    score: 60,
    gender: 0,
  },
  { id: 6, rank: 6, name: "Yusuf", surname: "Ozcan", score: 50, gender: 0 },
  {
    id: 7,
    rank: 7,
    name: "Umut Yilmaz",
    surname: "Gunduz",
    score: 40,
    gender: 0,
  },
  {
    id: 7,
    rank: 7,
    name: "Umut Yilmaz",
    surname: "Gunduz",
    score: 40,
    gender: 0,
  },
  {
    id: 7,
    rank: 7,
    name: "Umut Yilmaz",
    surname: "Gunduz",
    score: 40,
    gender: 0,
  },
  {
    id: 7,
    rank: 7,
    name: "Umut Yilmaz",
    surname: "Gunduz",
    score: 40,
    gender: 0,
  },
  {
    id: 7,
    rank: 7,
    name: "Umut Yilmaz",
    surname: "Gunduz",
    score: 40,
    gender: 0,
  },
];

export default function ProfileLeaderboard() {
  const dispatch = useDispatch();

  // const [linesAnimated, setLinesAnimated] = useState(false);

  const pageNumber = useSelector((state) => state.userAddGoal.pageNumber);
  const totalPage = useSelector((state) => state.userAddGoal.totalPage);

  const token = useSelector((state) => state.signIn.token);
  const leaderboard = useSelector((state) => state.userAddGoal.leaderboard);

  return (
    <div className={styles.leaderboard}>
      <header className={styles.header}>
        <div className={styles.icon}>
          <Leaderboard />
        </div>
        <div className={styles.leaderboardText}>Leaderboard</div>
      </header>
      {pageNumber === 1 && (
        <div className={styles.threeLine}>
          {leaderboard[1] && (
            <div className={styles.threeLine2}>
              <div className={styles.photo2}>
                <img src={leaderboard[1].gender ? girlAvatar : boyAvatar} />
              </div>
              <div className={styles.name2}>
                {leaderboard[1].name + " " + leaderboard[1].surname}
              </div>
              <div className={styles.point2}>{leaderboard[1].score}</div>
            </div>
          )}

          {leaderboard[0] && (
            <div className={styles.threeLine1}>
              <div className={styles.photo2}>
                <img src={leaderboard[0].gender ? girlAvatar : boyAvatar} />
              </div>
              <div className={styles.name2}>
                {leaderboard[0].name + " " + leaderboard[0].surname}
              </div>
              <div className={styles.point2}>{leaderboard[0].score}</div>
            </div>
          )}
          {leaderboard[2] && (
            <div className={styles.threeLine3}>
              <div className={styles.photo2}>
                <img src={leaderboard[2].gender ? girlAvatar : boyAvatar} />
              </div>
              <div className={styles.name2}>
                {leaderboard[2].name + " " + leaderboard[2].surname}
              </div>
              <div className={styles.point2}>{leaderboard[2].score}</div>
            </div>
          )}
        </div>
      )}
      {pageNumber === 1 && (
        <div className={styles.firstThree}>
          <div className={`${styles.container} ${styles.container2}`}>
            <div className={styles.secondUser}></div>
            <div className={`${styles.rectangle} ${styles.rectangle3}`}></div>
            <div className={`${styles.rectangle} ${styles.rectangle4}`}>2</div>
          </div>
          <div className={`${styles.container} ${styles.container1}`}>
            <div className={styles.firstUser}></div>
            <div className={`${styles.rectangle} ${styles.rectangle1}`}></div>
            <div className={`${styles.rectangle} ${styles.rectangle2}`}>1</div>
          </div>
          <div className={`${styles.container} ${styles.container3}`}>
            <div className={styles.thirdUser}></div>
            <div className={`${styles.rectangle} ${styles.rectangle5}`}></div>
            <div className={`${styles.rectangle} ${styles.rectangle6}`}>3</div>
          </div>
        </div>
      )}

      <main className={styles.main}>
        <div className={`${styles.leaderTable}`}>
          {leaderboard &&
            leaderboard.map(
              (item, index) =>
                (index > 2 || pageNumber > 1) && (
                  <div
                    // className={`${styles.line} ${
                    //   !linesAnimated ? styles.animateClass : ""
                    // }`}
                    className={styles.line}
                    // style={{ animationDelay: `${pageNumber === 1 ? (index - 3) * 0.1 : index * 0.1}s` }}
                    // onAnimationEnd={() => index + 1 === leaderboard.length && setLinesAnimated(true)}
                    key={Math.random()}
                  >
                    <div className={styles.leftPart}>
                      <div className={styles.photo}>
                        <img src={item.gender ? girlAvatar : boyAvatar} />
                      </div>
                      <div className={styles.name}>
                        {item.name + " " + item.surname}
                      </div>
                    </div>
                    <div className={styles.point}>{item.score}</div>
                    <div className={styles.rank}>
                      #
                      {pageNumber > 1
                        ? (pageNumber - 1) * 10 + index + 1
                        : index + 1}
                    </div>
                  </div>
                )
            )}
        </div>
        <LeaderboardPagination pageNumber={pageNumber} totalPage={totalPage} />
      </main>
    </div>
  );
}
