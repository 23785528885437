import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import userApi from "../apis/userApi";

export const getUserInfo = createAsyncThunk(
  "user/userInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.get("/info");
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: {
    status: "idle",
    error: null,
    userInformation: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userInformation = action.payload;
        state.status = "succeeded";
      })
      .addCase(getUserInfo.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const userInfoActions = userInfoSlice.actions;

export default userInfoSlice.reducer;
