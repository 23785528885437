import { useNavigate } from "react-router-dom";

import Image from "../../../assets/images/Pages/IntroductionPage/Image3.png";
import Image7 from "../../../assets/images/Pages/IntroductionPage/Image7.png";

import styles from "./IntroductionPartDown.module.css";

export default function IntroductionPartDown() {
  const navigate = useNavigate();

  const goToSignIn = (event) => {
    event.preventDefault();
    navigate("/signIn");
  };

  return (
    <div className={styles.main}>
      <div className={styles.gymPhoto}>
        <img src={Image7} className={styles.image} />
      </div>
      <div className={styles.paragraphContainer}>
        <div className={styles.paragraph}>
          Discover the power of AI with Eat Fit Pro's cutting-edge suite of
          tools, designed to revolutionize your fitness journey. Our Burned
          Calories Calculator utilizes advanced algorithms to accurately
          estimate the calories you burn during various activities, from brisk
          walking to intense workouts, tailored to your unique body metrics and
          exercise intensity. The Consumed Calories Calculator simplifies
          tracking your dietary intake, providing detailed insights into the
          calories and nutrients in your meals with just a few taps. And our
          standout feature, the Forecasting Tool, employs predictive analytics
          to project your fitness progress, helping you set realistic goals and
          stay motivated. Whether you're fine-tuning your diet, planning your
          exercise regime, or forecasting your fitness journey, Eat Fit Pro is
          here to guide you every step of the way. Embrace the future of fitness
          with Eat Fit Pro's AI-powered tools and transform your health and
          wellness journey today!
        </div>
      </div>
      <div className={styles.signInPart}>
        <div className={styles.signInText}>You can sign in now!</div>
        <button className={styles.signInButton} onClick={goToSignIn}>
          SIGN IN
        </button>
      </div>

      <div className={styles.icon}>
        <div className={styles.upCircle}></div>
        <div className={styles.connection}></div>
        <div className={styles.downCircle}></div>
      </div>
    </div>
  );
}
