import styles from "./ProgressBar.module.css";

export default function ProgressBar({ secondBgColor, whichPage }) {
  return (
    <aside className={styles.aside}>
      <div className={styles.firstPhase}>
        <div className={styles.numberFirstPhase}>1</div>
      </div>
      {whichPage == "First" ? (
        <div className={styles.progress}>
          <div className={styles.progressInside}></div>
          <div className={styles.progressInside}></div>
          <div className={styles.progressInside}></div>
          <div className={styles.progressInside}></div>
          <div className={styles.progressInside}></div>
          <div className={styles.progressInside}></div>
          <div className={styles.progressInside}></div>
          <div className={styles.progressInside}></div>
          <div className={styles.progressInside}></div>
        </div>
      ) : (
        <div className={styles.progress} style={{border: ".48vmin solid #00cd14"}}>
          <div className={styles.animateProgress}></div>
          <div className={styles.animateProgress}></div>
          <div className={styles.animateProgress}></div>
          <div className={styles.animateProgress}></div>
          <div className={styles.animateProgress}></div>
          <div className={styles.animateProgress}></div>
          <div className={styles.animateProgress}></div>
          <div className={styles.animateProgress}></div>
          <div className={styles.animateProgress}></div>
        </div>
      )}

      <div
        className={styles.secondPhase}
        style={{ backgroundColor: secondBgColor }}
      >
        <div className={styles.numberSecondPhase}>2</div>
      </div>
    </aside>
  );
}
