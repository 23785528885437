import dot from "../../../../../assets/images/Pages/ProfilePage/dot2.png";

import { useDispatch, useSelector } from "react-redux";
import { userAddingActivityActions } from "../../../../../redux/User/userAddingActivitySlice";
import {
  getActivities,
  getActivityCategories,
} from "../../../../../redux/User/userGettingActivitySlice";

import styles from "./HistoryActivityTable.module.css";

import TruncatedTextWithTooltip from "../../../../TruncatedTextWithTooltip/TruncatedTextWithTooltip";

export default function HistoryActivityTable({ list }) {
  const dispatch = useDispatch();
  const nullFilteredData = useSelector(
    (state) => state.userGettingActivity.nullFilteredData
  );
  const token = useSelector((state) => state.signIn.token);

  return (
    <div className={styles.main}>
      <div className={styles.mainTop}>
        <div className={styles.tableName}>
          <div className={styles.name}>
            <img src={dot} />
            <div>Activities</div>
          </div>
          <div className={styles.amount}>{list ? list.length : 0}</div>
        </div>
        <div key={Math.random()} className={styles.items}>
          {token &&
            list &&
            list.map((value) => (
              <div key={Math.random()} className={styles.item}>
                <TruncatedTextWithTooltip
                  text={value.activityDto.name}
                  className={styles.itemName}
                />
                <div className={styles.itemDuration}>
                  {parseInt(value.duration)} min
                </div>
                <div className={styles.itemCalories}>
                  {parseInt(value.caloriesBurned)} cal
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
