import { Link } from "react-router-dom";

import SignInForm from "../../components/Forms/SignInForm/SignInForm";
import Logo from "../../components/Logo/Logo";
import styles from "./SignInPage.module.css";

export default function SignInPage() {
  return (
    <main className={styles.main}>
      <div className={styles.logo}>
        <Logo width={9} />
      </div>
      <div className={styles.backgroundSignInForm}>
        <SignInForm />
      </div>
      <footer className={styles.footer}>
        <div>New to EFP?</div>
        <Link
          to="/signUpFirstPage"
          className={styles.createAccount}
          type="button"
        >
          Create an Account
        </Link>
      </footer>
    </main>
  );
}
