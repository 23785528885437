import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";

import signApi from "../apis/signApi";

import Cookies from 'js-cookie';

import { getToken } from "../../utils/getToken";

export const signIn = createAsyncThunk(
  "user/signin",
  async (userInfo, thunkAPI) => {
    try {
      const response = await signApi.post(
        "/auth/signin",
        JSON.stringify(userInfo)
      );
      Cookies.set('bearerToken', response.data.token, { expires: 60 });
      return response.data;
    } catch (error) {
      if (!error.response) {
        return thunkAPI.rejectWithValue({
          message: "No response from server. Please try again later.",
        });
      }
      if (error.response && !error.response.data) {
        return thunkAPI.rejectWithValue({
          message: "Server error without data.",
        });
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const signInSlice = createSlice({
  name: "signIn",
  initialState: {
    token: getToken(),
    status: "idle",
    error: null,
    userInformation: {
      email: "",
      password: "",
    },
    isClickedToSignInButton: false,
    eyeIsClicked: false,
  },
  reducers: {
    setEmail: (state, action) => {
      state.userInformation.email = action.payload;
    },
    setPassword: (state, action) => {
      state.userInformation.password = action.payload;
    },
    signOut: (state) => {
      state.token = null;
      state.status = "idle";
      Cookies.remove('bearerToken');
    },
    changeStatus: (state) => {
      state.status = "idle";
    },
    setIsClickedToSignInButton: (state) => {
      state.isClickedToSignInButton = !state.isClickedToSignInButton;
    },
    setEyeIsClicked: (state) => {
      state.eyeIsClicked = !state.eyeIsClicked;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.token = Cookies.get('bearerToken');
      })
      .addCase(signIn.pending, (state) => {
        state.status = "pending";
      })
      .addCase(signIn.rejected, (state) => {
        state.status = "failed";
        toast.error("Email or password is invalid. Please try again!", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  },
});

export const signInActions = signInSlice.actions;

export default signInSlice.reducer;
