import React from "react";
import styles from "./BurnedCalorieCard.module.css";

import speedImage from "../../../../../assets/images/Pages/ProfilePage/speed.png";

export default function BurnedCalorieCard({ calorie }) {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>
        <img src={speedImage} alt="speed" />
      </div>
      <div className={styles.firstText}>You Burned</div>
      <div className={styles.calorie}>
        {calorie}
        <span>&nbsp;cal</span>{" "}
      </div>
      <div className={styles.today}>Today</div>
    </div>
  );
}
