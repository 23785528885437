import Logo from "../../Logo/Logo";
import ProfileDropdown from "../ProfileDropDown/ProfileDropdown";
import styles from "./ProfileNavbar.module.css";
import UserInfo from "../UserInfo/UserInfo";
import { useSelector } from "react-redux";

export default function ProfileNavbar() {
  const userInformation = useSelector(
    (state) => state.userInfo.userInformation
  );
  const token = useSelector((state) => state.signIn.token);

  return (
    <nav className={styles.navbar}>
      <div className={styles.leftPart}>
        <UserInfo
          age={token && userInformation.age}
          weight={token && userInformation.weight}
          height={token && userInformation.length}
        /> 
      </div>
      <div className={styles.userName}>
        {token && userInformation.name + " " + userInformation.surname}
      </div>
      <div className={styles.profileIcon}>
        <ProfileDropdown />
      </div>
    </nav>
  );
}
