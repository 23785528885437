import dot from "../../../../assets/images/Pages/ProfilePage/dot2.png";
import plusIcon from "../../../../assets/images/Pages/ProfilePage/plus.png";

import { useDispatch, useSelector } from "react-redux";
import { userAddingActivityActions } from "../../../../redux/User/userAddingActivitySlice";
import {
  getActivityCategories,
  getActivities,
} from "../../../../redux/User/userGettingActivitySlice";

import ActivityAddingTable from "../ActivityAddingTable/ActivityAddingTable";
import styles from "./ActivityTable.module.css";

import TruncatedTextWithTooltip from "../../../TruncatedTextWithTooltip/TruncatedTextWithTooltip";

export default function ActivityTable() {
  const dispatch = useDispatch();
  const nullFilteredData = useSelector(
    (state) => state.userGettingActivity.nullFilteredData
  );
  const token = useSelector((state) => state.signIn.token);
  const activityList = useSelector((state) => state.userCalorieInfo.userActivityList);

  const addNewDataHandler = () => {
    dispatch(userAddingActivityActions.setIsOpen());
    dispatch(getActivities({ filteredData: nullFilteredData, page: 1 }));
    dispatch(getActivityCategories());
  };

  return (
    <div className={styles.main}>
      <div className={styles.mainTop}>
        <div className={styles.tableName}>
          <div className={styles.name}>
            <img src={dot} />
            <div>Activities</div>
          </div>
          <div className={styles.amount}>{activityList ? activityList.length : 0}</div>
        </div>
        <div key={Math.random()} className={styles.items}>
          {token && activityList &&
            activityList.map((value) => (
              <div key={Math.random()} className={styles.item}>
                <TruncatedTextWithTooltip
                  text={value.activityDto.name}
                  className={styles.itemName}
                />
                <div className={styles.itemDuration}>{parseInt(value.duration)} min</div>
                <div className={styles.itemCalories}>{parseInt(value.caloriesBurned)} cal</div>
              </div>
            ))}
        </div>
      </div>
      <button className={styles.newButton} onClick={addNewDataHandler}>
        <img src={plusIcon} />
        <div>New</div>
      </button>
      <ActivityAddingTable />
    </div>
  );
}
