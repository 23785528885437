import { createSlice } from "@reduxjs/toolkit";

export const userToolsSlice = createSlice({
  name: "userTools",
  initialState: {
    status: "idle",
    error: null,
    activeButton: "Panel",
    activeCalculatorButton: "Food",
    enabledTool: false
  },
  reducers: {
    setActiveButton: (state, action) => {
      state.activeButton = action.payload;
    },
    setActiveCalculatorButton: (state, action) => {
      state.activeCalculatorButton = action.payload;
    },
    setEnabledTool: (state) => {
      state.enabledTool = !state.enabledTool;
    },
  },
});

export const userToolsActions = userToolsSlice.actions;

export default userToolsSlice.reducer;
