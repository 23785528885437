import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import styles from "./Dropdown.module.css";

export default function Dropdown(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const closeDropdown = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };
  return (
    <div className={styles.dropdown} onBlur={closeDropdown}>
      <button onClick={toggleOpen} className={styles.dropbtn}>
        Tools
        {isOpen ? (
          <KeyboardArrowUpIcon fontSize="medium" />
        ) : (
          <KeyboardArrowDownIcon fontSize="medium" />
        )}
      </button>
      {isOpen && (
        <div className={styles.dropdownContent}>
          <a href="#">Link 1</a>
          <a href="#">Link 2</a>
          <a href="#">Link 3</a>
        </div>
      )}
    </div>
  );
}
