import { Box, TextField } from "@mui/material";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  getHistory,
  userCalorieHistoryActions,
} from "../../../../redux/User/userCalorieHistorySlice";

import FilterIcon from "../../../../assets/images/Pages/ProfilePage/filter.png";

import { useDispatch, useSelector } from "react-redux";
import styles from "./HistoryFilter.module.css";

export default function HistoryFilter() {
  const dispatch = useDispatch();

  const filteredData = useSelector(
    (state) => state.userCalorieHistory.filteredData
  );

  const page = useSelector((state) => state.userCalorieHistory.pageNumber);
  const date = useSelector(
    (state) => state.userCalorieHistory.filteredData.date
  );

  const filterHandler = (event) => {
    event.preventDefault();
    dispatch(getHistory({ filteredData: filteredData, page: page }));
    dispatch(userCalorieHistoryActions.setPageNumber(1));
    dispatch(userCalorieHistoryActions.setClicked());
  };

  return (
    <Box component="form" autoComplete="off">
      <div
        id="transition-modal-title"
        variant="h6"
        component="h2"
        className={styles.filterPart}
      >
        <TextField
          label="Food Name"
          size="small"
          onChange={(e) =>
            dispatch(userCalorieHistoryActions.setFoodName(e.target.value))
          }
          className={styles.foodName}
        />
        <TextField
          label="Activity Name"
          size="small"
          style={{ marginLeft: ".5vw" }}
          onChange={(e) =>
            dispatch(userCalorieHistoryActions.setActivityName(e.target.value))
          }
          className={styles.activityName}
        />
        <DatePicker
          className={styles.datePicker}
          placeholderText="Date"
          isClearable
          selected={date}
          onChange={(value) => {
            if (value) {
              dispatch(userCalorieHistoryActions.setDate(value.toISOString()));
            } else {
              dispatch(userCalorieHistoryActions.setDate(null));
            }
          }}
        />

        <button
          className={styles.filterButton}
          onClick={filterHandler}
          type="submit"
        >
          <div>Filter</div>
          <img src={FilterIcon} />
        </button>
      </div>
    </Box>
  );
}
