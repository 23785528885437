import axios from "axios";
import Cookies from 'js-cookie';

const userApi = axios.create({
  baseURL: "https://eatfitpro-spring.onrender.com/api/v1/user",
  headers: {
    "Content-Type": "application/json",
  },
});

userApi.interceptors.request.use(
  (config) => {
    const token = Cookies.get("bearerToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default userApi;
