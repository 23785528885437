import React from "react";
import styles from "./BalanceCalorieCard.module.css";

import balanceImage from "../../../../../assets/images/Pages/ProfilePage/balance.png";

export default function BalanceCalorieCard({ calorie }) {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>
        <img src={balanceImage} alt="speed" />
      </div>
      <div className={styles.firstText}>Calories Balance</div>
      <div className={styles.calorie}>
        {calorie > 0 ? `+${calorie}` : calorie}
        <span>&nbsp;cal</span>
        <span>&nbsp;</span>{" "}
      </div>
      <div className={styles.today}>Today</div>
    </div>
  );
}
