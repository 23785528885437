import React, { useEffect, useRef, useState } from "react";
import Clock from "react-simple-clock";

import styles from "./TodayCard.module.css";

export default function TodayCard() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const seconds = currentDate.getSeconds();
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  const secondDeg = (seconds / 60) * 360 + 90;
  const minuteDeg = (minutes / 60) * 360 + (seconds / 60) * 6 + 90;
  const hourDeg = (hours / 12) * 360 + (minutes / 60) * 30 + 90;
  

  const hourMarks = [];
  for (let i = 0; i < 12; i++) {
    hourMarks.push(
      <div
        key={i}
        className={styles.mark}
        style={{ transform: `rotate(${i * 30}deg)` }}
      >
        <div style={{ transform: `translate(-50%, ${13.4075}vmin)` }} />
      </div>
    );
  }

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
      setWidth(window.innerWidth);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={styles.card}>
      <div className={styles.dayAndMonth}>
        <span>{day}</span>
        {month}
      </div>
      <div className={styles.year}>{year}</div>
      <div className={styles.today}>
        {hours}:{minutes}
      </div>
      <Clock live={true} className={styles.analogClock} mode="dark" size={width / 11} />

      {/* <div className={styles.clock}>
        {hourMarks}
        <div
          className={`${styles.hand} ${styles.hour}`}
          style={{ transform: `rotate(${hourDeg}deg)` }}
        />
        <div
          className={`${styles.hand} ${styles.minute}`}
          style={{ transform: `rotate(${minuteDeg}deg)` }}
        />
        <div
          className={`${styles.hand} ${styles.second}`}
          style={{ transform: `rotate(${secondDeg}deg)` }}
        />
        <div className={styles.centerPoint} />
      </div> */}
    </div>
  );
}
