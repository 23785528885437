import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import UserCalorieHistoryPagination from "../../TablePagination/UserCalorieHistoryPagination";

import HistoryListItem from "./HistoryListItem";

import styles from "./HistoryList.module.css";
import { useEffect } from "react";
import {
  userCalorieHistoryActions,
  getHistory,
} from "../../../../redux/User/userCalorieHistorySlice";

export default function HistoryList() {
  const dispatch = useDispatch();

  const info = useSelector(
    (state) => state.userCalorieHistory.userHistory.content
  );

  const statusHistory = useSelector((state) => state.userCalorieHistory.status);

  const isClicked = useSelector((state) => state.userCalorieHistory.isClicked);

  const pageNumber = useSelector(
    (state) => state.userCalorieHistory.pageNumber
  );
  const totalPage = useSelector((state) => state.userCalorieHistory.totalPage);
  const filteredData = useSelector(
    (state) => state.userCalorieHistory.filteredData
  );

  const token = useSelector((state) => state.signIn.token);

  useEffect(() => {
    dispatch(
      getHistory({
        filteredData: filteredData,
        page: pageNumber,
      })
    );
  }, [isClicked]);

  return (
    <main className={styles.main}>
      {token && info && statusHistory !== "pending" ? (
        info.length > 0 ? info.map((item) => <HistoryListItem key={Math.random()} data={item} />) : <div className={styles.noHistory}>No results found.</div>
      ) : (
        <div className={styles.circularContainer}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.pagination}>
        <UserCalorieHistoryPagination
          pageNumber={pageNumber}
          totalPage={totalPage}
        />
      </div>
    </main>
  );
}
