import {
  Box,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signUp, signUpActions } from "../../../redux/SignUp/signUpSlice";
import { toast } from "react-toastify";
import styles from "./SignUpSecondForm.module.css";

export default function SignUpSecondForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gender = useSelector((state) => state.signUp.userInformation.gender);
  const signUpStatus = useSelector((state) => state.signUp.signUpStatus);
  const userInformation = useSelector((state) => state.signUp.userInformation);

  const length = useSelector((state) => state.signUp.userInformation.length);
  const weight = useSelector((state) => state.signUp.userInformation.weight);
  const age = useSelector((state) => state.signUp.userInformation.age);

  const finishSignUp = (event) => {
    event.preventDefault();
    if (
      length.trim() !== "" &&
      weight.trim() !== "" &&
      age.trim() !== "" &&
      gender.trim() !== ""
    ) {
      dispatch(signUp(userInformation));
    } else {
      toast.error("Please fill in all required fields to continue.", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  useEffect(() => {
    if (signUpStatus === "succeeded") {
      navigate("/signUpVerifyPage");
    }
  }, [signUpStatus]);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch", background: "white" },
      }}
      noValidate
      autoComplete="off"
      className={styles.form}
    >
      {signUpStatus === "pending" ? (
        <CircularProgress />
      ) : (
        <div className={styles.form}>
          <div className={styles.nameSurname}>
            <div className={styles.nameInput}>
              <InputLabel
                style={{
                  color: "black",
                  marginLeft: `${0.5}vw`,
                  fontSize: `${2.37875}vmin`,
                }}
                htmlFor="length"
              >
                Length
              </InputLabel>
              <TextField
                style={{ width: `${7.2}vw` }}
                id="length"
                name="length"
                size="small"
                type="number"
                autoFocus
                onChange={(e) =>
                  dispatch(signUpActions.setLength(e.target.value))
                }
              />
            </div>

            <div className={styles.surnameInput}>
              <InputLabel
                style={{
                  color: "black",
                  marginLeft: `${0.5}vw`,
                  fontSize: `${2.37875}vmin`,
                }}
                htmlFor="weight"
              >
                Weight
              </InputLabel>
              <TextField
                style={{ width: `${7.2}vw` }}
                id="weight"
                name="weight"
                size="small"
                type="number"
                onChange={(e) =>
                  dispatch(signUpActions.setWeight(e.target.value))
                }
              />
            </div>
          </div>

          <div>
            <InputLabel style={{ color: "black", fontSize: `${2.37875}vmin` }}>
              Gender
            </InputLabel>
            <Select
              id="gender"
              name="gender"
              value={gender}
              style={{ width: `${15.5}vw`, backgroundColor: "white" }}
              size="small"
              onChange={(e) =>
                dispatch(signUpActions.setGender(e.target.value))
              }
            >
              <MenuItem value="false">Male</MenuItem>
              <MenuItem value="true">Female</MenuItem>
            </Select>
          </div>

          <div>
            <InputLabel
              style={{
                color: "black",
                marginLeft: `${0.5}vw`,
                fontSize: `${2.37875}vmin`,
              }}
              htmlFor="age"
            >
              Age
            </InputLabel>
            <TextField
              style={{ width: `${15.5}vw` }}
              id="age"
              name="age"
              size="small"
              type="number"
              onChange={(e) => dispatch(signUpActions.setAge(e.target.value))}
            />
          </div>

          <button
            className={styles.btn_signUp}
            onClick={finishSignUp}
            type="submit"
          >
            FINISH
          </button>
        </div>
      )}
    </Box>
  );
}
