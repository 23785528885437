import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import userApi from "../apis/userApi";

export const getUserCalorieInfo = createAsyncThunk(
  "user/userCalorieInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.get("/assignment");
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userCalorieInfoSlice = createSlice({
  name: "userCalorieInfo",
  initialState: {
    status: "idle",
    error: null,
    userCalorieInformation: {},
    userFoodList: [],
    userActivityList: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserCalorieInfo.fulfilled, (state, action) => {
        state.userCalorieInformation = action.payload;
        state.userFoodList = action.payload.foodDtoList;
        state.userActivityList = action.payload.activityDtoList;
        state.status = "succeeded";
      })
      .addCase(getUserCalorieInfo.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getUserCalorieInfo.rejected, (state, action) => {
        state.status = "failed";
      })
  },
});

export const userCalorieInfoActions = userCalorieInfoSlice.actions;

export default userCalorieInfoSlice.reducer;
