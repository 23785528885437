import React, { useState } from "react";

import { useSelector } from "react-redux";

import boyAvatar from "../../../assets/images/Pages/ProfilePage/boyAvatar.png";
import girlAvatar from "../../../assets/images/Pages/ProfilePage/girlAvatar.png";

import { useDispatch } from "react-redux";
import { signInActions } from "../../../redux/SignIn/signInSlice";

import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";

import { Link } from "react-router-dom";
import styles from "./ProfileDropdown.module.css";
import { getUserGraphs, userCalorieGraphActions } from "../../../redux/User/userCalorieGraphSlice";

export default function ProfileDropdown() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const gender = useSelector(state => state.userInfo.userInformation.gender)
  const token = useSelector(state => state.signIn.token)

  const toggleOpen = () => setIsOpen(!isOpen);

  const closeDropdown = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };
  return (
    <div className={styles.dropdown} onBlur={closeDropdown}>
      <button onClick={toggleOpen} className={styles.dropbtn}>
        <img src={(token && gender === false) ? boyAvatar : girlAvatar} />
      </button>
      {isOpen && (
        <div className={styles.dropdownContent}>
          <a href="#">
            <div className={styles.icon}>
              <PersonIcon />
            </div>
            My Profile
          </a>
          <a href="#">
            <div className={styles.icon}>
              <SettingsIcon />
            </div>
            Settings
          </a>
          <button
            style={{ all: "unset", width: "100%", cursor: "pointer" }}
            onClick={() => {
              dispatch(signInActions.signOut());
              dispatch(userCalorieGraphActions.setUserGraph())
            }}
          >
            <a>
              <div className={styles.icon}>
                <LogoutIcon />
              </div>
              Sign Out
            </a>
          </button>
        </div>
      )}
    </div>
  );
}
