import HistoryActivityTable from "../HistoryTable/HistoryActivityTable/HistoryActivityTable";
import HistoryFoodTable from "../HistoryTable/HistoryFoodTable/HistoryFoodTable";

import balanceImage from "../../../../assets/images/Pages/ProfilePage/blackBalance.png";
import foodImage from "../../../../assets/images/Pages/ProfilePage/blackFood.png";
import exercise from "../../../../assets/images/Pages/ProfilePage/blackExercise.png";
import CalendarIcon from "../../../../assets/images/Pages/ProfilePage/calendar.png";

import styles from "./HistoryListItem.module.css";

export default function HistoryListItem({ data }) {
  const [year, month, day] = data.date.split("-");
  const dateObj = new Date(year, month - 1, day);
  const formattedDate = dateObj.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  return (
    <div>
      <main className={styles.main}>
        <div className={styles.firstPart}>
          <div className={styles.date}>
            <img src={CalendarIcon} />
            <div className={styles.dateTitle}>{formattedDate}</div>
          </div>
          <div className={styles.caloriesInfo}>
            <div className={styles.calorieCard}>
              <div className={styles.icon}>
                <img src={foodImage} />
              </div>
              <div className={styles.info}>
                <div>Consumed</div>
                <div>{data.totalConsumedCalories} cal</div>
              </div>
            </div>
            <div className={styles.calorieCard}>
              <div className={styles.icon}>
                <img src={exercise} />
              </div>
              <div className={styles.info}>
                <div>Burned</div>
                <div>{data.totalBurnedCalories} cal</div>
              </div>
            </div>
            <div className={styles.calorieCard}>
              <div className={styles.icon}>
                <img src={balanceImage} />
              </div>
              <div className={styles.info}>
                <div>Balance</div>
                <div>{data.balance} cal</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.secondPart}>
          <HistoryFoodTable list={data.foodDtoList} />
        </div>
        <div className={styles.thirdPart}>
          <HistoryActivityTable list={data.activityDtoList} />
        </div>
      </main>
      <div className={styles.divider}></div>
    </div>
  );
}
