import { Link } from "react-router-dom";

import styles from "./NavigationBar.module.css";
import Logo from "../../Logo/Logo";
import Dropdown from "../../Dropdown/Dropdown";
import { useEffect, useState } from "react";

export default function NavigationBar() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  return (
    <>
      <div className={styles.navigationBar}>
        <div className={styles.leftPart}>
          <div className={styles.logo}>
            <Logo width={3.8} />
          </div>
          <div className={styles.tools}>
            <Dropdown scrolled={scrolled} />
            <button className={styles.about}>About</button>
          </div>
        </div>
        <div className={styles.rightPart}>
          <button className={styles.signUpButton}>
            <Link to="/signUpFirstPage">Sign Up</Link>
          </button>
          <Link to="/signIn">
            <button className={styles.signInButton}>Sign In</button>
          </Link>
        </div>
      </div>
    </>
  );
}
