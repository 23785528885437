import { useSelector, useDispatch } from "react-redux";
import { userToolsActions } from "../../redux/User/userToolsSlice";

import FoodCalculatorTable from "../../components/Profile/CalorieCalculator/FoodCalculator/FoodCalculatorTable";
import ActivityCalculatorTable from "../../components/Profile/CalorieCalculator/ActivityCalculator/ActivityCalculatorTable";

import {
  getFoodsCalculator,
  userFoodCalorieCalculatorActions,
} from "../../redux/User/userFoodCalorieCalculatorSlice";
import {
  getActivitiesCalculator,
  userActivityCalorieCalculatorActions,
} from "../../redux/User/userActivityCalorieCalculatorSlice";

import styles from "./ProfileCalorieCalculatorPage.module.css";

export default function ProfileCalorieCalculatorPage() {
  const dispatch = useDispatch();

  const activeCalculatorButton = useSelector(
    (state) => state.userTools.activeCalculatorButton
  );

  const foodFilteredData = useSelector(
    (state) => state.userFoodCalculator.filteredData
  );

  const activityFilteredData = useSelector(
    (state) => state.userActivityCalculator.filteredData
  );

  const token = useSelector((state) => state.signIn.token);

  return (
    <main className={styles.main}>
      <div className={styles.pageButtons}>
        <button
          className={`${styles.foodCalculator} ${
            activeCalculatorButton === "Food" && styles.enabledButton
          }`}
          onClick={() => {
            dispatch(userToolsActions.setActiveCalculatorButton("Food"));
            dispatch(
              userActivityCalorieCalculatorActions.setActivityRecordNull()
            );
            dispatch(userActivityCalorieCalculatorActions.setResultNone());
            dispatch(
              userActivityCalorieCalculatorActions.setFilteredDataNull()
            );
            dispatch(
              getFoodsCalculator({ filteredData: foodFilteredData, page: 1 })
            );
          }}
        >
          Food Calculator
        </button>
        <button
          className={`${styles.activityCalculator} ${
            activeCalculatorButton === "Activity" && styles.enabledButton
          }`}
          onClick={() => {
            dispatch(userToolsActions.setActiveCalculatorButton("Activity"));
            dispatch(userFoodCalorieCalculatorActions.setFoodRecordNull());
            dispatch(userFoodCalorieCalculatorActions.setResultNone());
            dispatch(userFoodCalorieCalculatorActions.setFilteredDataNull());
            dispatch(
              getActivitiesCalculator({
                filteredData: activityFilteredData,
                page: 1,
              })
            );
          }}
        >
          Activity Calculator
        </button>
      </div>
      {activeCalculatorButton === "Food" && token && (
        <div className={styles.foodCalculatorPage}>
          <FoodCalculatorTable />
        </div>
      )}
      {activeCalculatorButton === "Activity" && token && (
        <div className={styles.activityCalculatorPage}>
          <ActivityCalculatorTable />
        </div>
      )}
    </main>
  );
}
