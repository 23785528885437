import React from "react";
import styles from "./ConsumedCalorieCard.module.css";

import saladImage from "../../../../../assets/images/Pages/ProfilePage/salad.png";

export default function ConsumedCalorieCard({ calorie }) {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>
        <img src={saladImage} alt="salad" />
      </div>
      <div className={styles.firstText}>You Consumed</div>
      <div className={styles.calorie}>
        {calorie}
        <span>&nbsp;cal</span>{" "}
      </div>
      <div className={styles.today}>Today</div>
    </div>
  );
}
