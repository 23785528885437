import "react-circular-progressbar/dist/styles.css";

// import { Progress } from "react-sweet-progress";
// import "react-sweet-progress/lib/style.css";

import { Heat } from "@alptugidin/react-circular-progress-bar";

import {
  getGoalUserCalorieInfo,
  getGoalUserInfo,
  getUserGoal,
  setUserGoal,
  userAddGoalActions,
} from "../../../redux/User/userAddGoalSlice";

import { Box, TextField } from "@mui/material";

import applyImage from "../../../assets/images/Pages/ProfilePage/apply.png";
import bottomArrow from "../../../assets/images/Pages/ProfilePage/bottomArrow.png";
import goalImage from "../../../assets/images/Pages/ProfilePage/goal.png";

import { useDispatch, useSelector } from "react-redux";

import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import styles from "./ProfileGoal.module.css";

export default function ProfileGoal() {
  const dispatch = useDispatch();

  const setGoal = useSelector((state) => state.userAddGoal.setGoal);
  const userInformation = useSelector(
    (state) => state.userAddGoal.userInformation
  );
  const dailyGoal = useSelector((state) => state.userAddGoal.dailyGoal);
  const calorieBalance = useSelector(
    (state) => state.userAddGoal.calorieBalance
  );

  const token = useSelector((state) => state.signIn.token);

  const refresh = useSelector((state) => state.userAddGoal.refresh);

  const [isClicked, setIsClicked] = useState(false);
  const [isClickedToSetButton, setIsClickedToSetButton] = useState(false);

  const inputRef = useRef(null);

  const setGoalHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(userAddGoalActions.setRefresh());
    if (setGoal.trim() !== "" && setGoal != 0) {
      dispatch(setUserGoal(setGoal));
      setIsClickedToSetButton((prev) => !prev);
      flipCard();
    } else {
      toast.error("Daily goal must not be empty or 0.", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const flipCard = () => {
    setIsClicked((prev) => !prev);
    dispatch(userAddGoalActions.setGoal(""));
    if (!isClicked) {
      inputRef.current.focus();
    }
    if (isClickedToSetButton) {
      setIsClickedToSetButton((prev) => !prev);
    }
  };

  const handleInputClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (token && refresh) {
      dispatch(userAddGoalActions.setRefresh());
      dispatch(getUserGoal());
      dispatch(getGoalUserInfo());
      dispatch(getGoalUserCalorieInfo());
    } else {
      dispatch(getGoalUserInfo());
      dispatch(getGoalUserCalorieInfo());
    }
  }, [dispatch, dailyGoal, calorieBalance, refresh, token]);

  return (
    <div className={styles.main}>
      <div className={styles.cards}>
        <div
          className={`${styles.cardContainer} ${isClicked && styles.isFlipped}`}
        >
          <div className={styles.card}>
            <div className={styles.cardFront} onClick={flipCard}>
              <img src={bottomArrow} className={styles.arrowImage} />
              <div className={styles.buttonText}>
                Click here to change your daily goal.
              </div>
            </div>
            <div className={styles.cardBack} onClick={flipCard}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                className={styles.changeGoal}
              >
                <TextField
                  sx={{
                    width: `${8}vw`,
                    backgroundColor: "#E8F0FE",
                    borderRadius: 5,
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "transparent",
                      },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  id="addGoal"
                  name="addGoal"
                  placeholder="Daily Goal"
                  size="small"
                  type="email"
                  value={setGoal}
                  onChange={(e) =>
                    dispatch(userAddGoalActions.setGoal(e.target.value))
                  }
                  inputRef={inputRef}
                  onClick={handleInputClick}
                />
                <button
                  className={styles.applyButton}
                  type="submit"
                  onClick={setGoalHandler}
                >
                  <img src={applyImage} />
                </button>
              </Box>
            </div>
          </div>
        </div>
        <div className={styles.circularGoal}>
          <div className={styles.circularCard}>
            <div className={styles.progressBar}>
              <Heat
                progress={
                  token
                    ? calorieBalance != 0
                      ? parseInt((calorieBalance * 100) / dailyGoal)
                      : 0
                    : 0
                }
                range={{ from: 0, to: 100 }}
                revertBackground={true}
                sx={{
                  bgColor: "rgba(255, 255, 255, .3)",
                  barWidth: 10,
                  valueSize: 18,
                  valueColor: "#fff",
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`${styles.info} ${
            isClickedToSetButton && styles.rotateFull
          }`}
        >
          <div className={styles.infoCard}>
            <div className={styles.icon}>
              <img src={goalImage} alt="goal" />
            </div>
            <div className={styles.firstText}>Daily Goal</div>
            <div className={styles.calorie}>
              {dailyGoal}
              <span>&nbsp;cal</span>
              <span>&nbsp;</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.goalText}>
        {dailyGoal >= 0
          ? calorieBalance >= dailyGoal
            ? "Congratulations! You have reached your daily calorie goal."
            : "You need to consume a few more calories to reach your goal."
          : calorieBalance <= dailyGoal
          ? "Congratulations! You have reached your daily calorie goal."
          : "You need to burn a few more calories to reach your goal."}
      </div>
    </div>
  );
}
