import { predictCalories } from "../../redux/User/userCaloriePredictorSlice";
import { useSelector } from "react-redux";

import styles from "./ProfilePredictorPage.module.css";

export default function ProfilePredictorPage() {
  const prediction = useSelector(state => state.userCaloriePredictor.prediction)

  return <div className={styles.predictMain}>{prediction}</div>;
}
