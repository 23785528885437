import SignUpSecondForm from "../../components/Forms/SignUpForm/SignUpSecondForm";
import Logo from "../../components/Logo/Logo";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import styles from "./SignUpSecondPage.module.css";
import { Link } from "react-router-dom";

export default function SignUpSecondPage() {
  return (
    <main className={styles.main}>
      <div className={styles.logo}>
        <Logo width={9} />
      </div>
      <div className={styles.backgroundSignUpForm}>
        <SignUpSecondForm />
        <ProgressBar whichPage="Second" secondBgColor="#00cd14" />
      </div>
      <footer className={styles.footer}>
        <div>Already Have an Account?</div>
        <Link to="/signIn" className={styles.signIn}>
          Sign In
        </Link>
      </footer>
    </main>
  );
}
