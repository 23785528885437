import React, { useEffect } from "react";
import Graph from "../../../components/Graph/Graph";
import { useSelector } from "react-redux";
import styles from "./GraphSection.module.css";

export default function GraphSection({ userGraphs }) {
  const token = useSelector((state) => state.signIn.token);

  const nullData = [
    {
      date: "1990-01-01",
      calories: 0,
    },
  ];

  let consumedCalorie;
  let burnedCalorie;
  let balanceCalorie;

  if (token) {
    consumedCalorie = userGraphs.consumedHistoriesDtoList;
    burnedCalorie = userGraphs.burnedHistoriesDtoList;
    balanceCalorie = userGraphs.balanceHistoriesDtoList;
  }

  return (
    <div className={styles.graphs}>
      <div className={styles.topGraph}>
        <div className={styles.graphsTitle}>History Graphs</div>
        <div className={styles.divider}></div>
      </div>
      <div className={`${styles.graph} ${styles.consumedGraph}`}>
        <div className={styles.title}>Consumed Calories</div>
        <Graph calorieData={consumedCalorie ? consumedCalorie : nullData} />
      </div>
      <div className={`${styles.graph} ${styles.burnedGraph}`}>
        <div className={styles.title}>Burned Calories</div>
        <Graph calorieData={burnedCalorie ? burnedCalorie : nullData} />
      </div>
      <div className={`${styles.graph} ${styles.balanceGraph}`}>
        <div className={styles.title}>Balance Calories</div>
        <Graph calorieData={balanceCalorie ? balanceCalorie : nullData} />
      </div>
    </div>
  );
}
