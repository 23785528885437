import React from "react";
import { ResizableBox as ReactResizableBox } from "react-resizable";

export default function ResizableBox(
  {
  children,
  width = 45,
  height = 50,
  resizable = false,
}) {
  return (
    <div style={{ marginLeft: `1vw` }}>
      <div
        style={{
          display: "inline-block",
          width: "auto",
          background: "white",
          padding: "1.125vh .5vw",
          borderRadius: "1.08125vmin",
          boxShadow: "0 30px 40px rgba(0,0,0,.1)",
        }}
      >
        {resizable ? (
          <ReactResizableBox width={width} height={height}>
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              {children}
            </div>
          </ReactResizableBox>
        ) : (
          <div
            style={{
              width: `${width}vw`,
              height: `${height}vh`,
            }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
}
