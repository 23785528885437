import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import searchIcon from "../../../../assets/images/Pages/ProfilePage/search.png";
import {
  addingActivityRecord,
  userAddingActivityActions,
} from "../../../../redux/User/userAddingActivitySlice";
import { getUserGraphs } from "../../../../redux/User/userCalorieGraphSlice";
import { getUserCalorieInfo } from "../../../../redux/User/userCalorieInfoSlice";
import {
  getActivities,
  userGettingActivityActions,
} from "../../../../redux/User/userGettingActivitySlice";
import ActivityTablePagination from "../../TablePagination/ActivityTablePagination";
import styles from "./ActivityAddingTable.module.css";
import { toast } from "react-toastify";
import { getUserGoal } from "../../../../redux/User/userAddGoalSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "39vw",
  height: "70vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "4.5vh 2vw",
  borderRadius: "1.85vmin",
};

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 240,
    sortable: false,
    filterable: false,
  },
  {
    field: "activityCategoryDto",
    headerName: "Category",
    width: 240,
    sortable: false,
    filterable: false,
    valueGetter: (params) => params.row?.activityCategoryDto?.name,
  },
];

export default function ActivityAddingTable() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.userAddingActivity.isOpen);
  const token = useSelector((state) => state.signIn.token);
  const filteredData = useSelector(
    (state) => state.userGettingActivity.filteredData
  );
  const pageNumber = useSelector(
    (state) => state.userGettingActivity.pageNumber
  );
  const totalPage = useSelector((state) => state.userGettingActivity.totalPage);
  const activityCategories = useSelector(
    (state) => state.userGettingActivity.activityCategories
  );
  const activities = useSelector(
    (state) => state.userGettingActivity.activities
  );
  const category = useSelector(
    (state) => state.userGettingActivity.filteredData.category
  );
  const activityRecord = useSelector(
    (state) => state.userAddingActivity.activityRecord
  );
  const addingStatus = useSelector((state) => state.userAddingActivity.status);

  const id = useSelector(
    (state) => state.userAddingActivity.activityRecord.activityId
  );
  const duration = useSelector(
    (state) => state.userAddingActivity.activityRecord.duration
  );
  const heartRate = useSelector(
    (state) => state.userAddingActivity.activityRecord.heartRate
  );
  const bodyTemp = useSelector(
    (state) => state.userAddingActivity.activityRecord.bodyTemp
  );

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.bodyWithModalOpen);
    } else {
      document.body.classList.remove(styles.bodyWithModalOpen);
    }

    return () => {
      document.body.classList.remove(styles.bodyWithModalOpen);
    };
  }, [isOpen]);

  const addActivityHandler = (event) => {
    event.preventDefault();
    if (duration && id && duration > 0 && heartRate > 0 && bodyTemp > 0) {
      dispatch(addingActivityRecord(activityRecord));
      dispatch(userAddingActivityActions.setRefresh());
      dispatch(getUserCalorieInfo());
      dispatch(getUserGraphs());
      dispatch(userAddingActivityActions.setActivityRecordNull());
      dispatch(getUserGoal());
    } else {
      toast.error(
        "You must fill all areas and choose an activity from the table. You can't enter negative values.",
        {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={() => {
        dispatch(userAddingActivityActions.setIsOpen());
        dispatch(userAddingActivityActions.setActivityRecordNull());
        dispatch(userGettingActivityActions.setFilteredDataNull());
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className={styles.main}>
        <Fade in={isOpen}>
          {addingStatus !== "pending" ? (
            <Box sx={style}>
              <Box component="form" autoComplete="off">
                <div
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  className={styles.filterPart}
                >
                  <Autocomplete
                    getOptionLabel={(option) => option.name}
                    clearOnEscape
                    options={activityCategories}
                    size="small"
                    value={category}
                    renderInput={(params) => (
                      <TextField {...params} label="All Categories" />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        dispatch(userGettingActivityActions.setCategory(value));
                        dispatch(
                          userGettingActivityActions.setActivityCategoryName(
                            value.name
                          )
                        );
                      } else {
                        dispatch(userGettingActivityActions.setCategory(null));
                        dispatch(
                          userGettingActivityActions.setActivityCategoryName("")
                        );
                      }
                    }}
                    className={styles.categories}
                  />
                  <TextField
                    label="Name"
                    size="small"
                    onChange={(e) =>
                      dispatch(
                        userGettingActivityActions.setName(e.target.value)
                      )
                    }
                    className={styles.name}
                  />
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      dispatch(
                        getActivities({ filteredData: filteredData, page: 1 })
                      );
                      dispatch(userGettingActivityActions.setPageNumber(1));
                    }}
                    className={styles.filterButton}
                    type="submit"
                  >
                    <div>FIND</div>
                    <img src={searchIcon} />
                  </button>
                </div>
              </Box>
              <Box component="form" autoComplete="off" className={styles.calculateContainer}>
                <div id="transition-modal-description" sx={{ mt: 2 }}>
                  <DataGrid
                    rows={activities}
                    columns={columns}
                    hideFooter
                    style={{
                      maxHeight: `${39.6}vh`,
                      minHeight: `${39.6}vh`,
                      width: `${35.2}vw`,
                      marginTop: `${1}vw`,
                      fontSize: "1.94625vmin",
                    }}
                    onRowClick={(params) => {
                      dispatch(
                        userAddingActivityActions.setAddingActivityId(
                          params.row.id
                        )
                      );
                      dispatch(
                        userAddingActivityActions.setAddingActivityHeartRate(
                          params.row.heartBeat
                        )
                      );
                      dispatch(
                        userAddingActivityActions.setAddingActivityBodyTemp(
                          params.row.bodyTemp
                        )
                      );
                    }}
                  />
                  <ActivityTablePagination
                    pageNumber={pageNumber}
                    totalPage={totalPage}
                  />
                </div>
                <div
                  variant="h6"
                  component="h2"
                  sx={{ mt: 2 }}
                  className={styles.addPart}
                >
                  <TextField
                    label="Duration (min)"
                    size="small"
                    onChange={(e) =>
                      dispatch(
                        userAddingActivityActions.setAddingActivityDuration(
                          e.target.value
                        )
                      )
                    }
                    className={styles.duration}
                  />
                  <TextField
                    value={
                      activityRecord.heartRate ? activityRecord.heartRate : 0
                    }
                    label="Heart Rate"
                    size="small"
                    onChange={(e) =>
                      dispatch(
                        userAddingActivityActions.setAddingActivityHeartRate(
                          e.target.value
                        )
                      )
                    }
                    className={styles.heartRate}
                  />
                  <TextField
                    label="Body Temperature"
                    value={
                      activityRecord.bodyTemp ? activityRecord.bodyTemp : 0
                    }
                    size="small"
                    onChange={(e) =>
                      dispatch(
                        userAddingActivityActions.setAddingActivityBodyTemp(
                          e.target.value
                        )
                      )
                    }
                    className={styles.bodyTemp}
                  />
                  <button
                    onClick={addActivityHandler}
                    className={styles.addButton}
                  >
                    Add
                  </button>
                </div>
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={style}
            >
              <CircularProgress />
            </Box>
          )}
        </Fade>
      </div>
    </Modal>
  );
}
