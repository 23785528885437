import { Box, CircularProgress, InputLabel, TextField, InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changePassword,
  userForgotPasswordActions,
} from "../../../redux/User/userForgotPasswordSlice";

import openEye from "../../../assets/images/Pages/SignPages/openEye.png"
import closeEye from "../../../assets/images/Pages/SignPages/closeEye.png"

import styles from "./ForgotPasswordSecondPageForm.module.css";

import { toast } from "react-toastify";

export default function ForgotPasswordSecondPageForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const passwordInformation = useSelector(
    (state) => state.userForgotPassword.changePassword
  );
  const changeStatus = useSelector(
    (state) => state.userForgotPassword.changeStatus
  );
  const changeIsSuccessful = useSelector(
    (state) => state.userForgotPassword.changeIsSuccessful
  );

  const isClickedToSecondPage = useSelector(
    (state) => state.userForgotPassword.isClickedToSecondPage
  );

  const newPassword = useSelector(
    (state) => state.userForgotPassword.changePassword.password
  );
  const confirmPassword = useSelector(
    (state) => state.userForgotPassword.confirmPassword
  );
  const eyeIsClicked = useSelector((state) => state.userForgotPassword.eyeIsClicked);

  const changePasswordHandler = (event) => {
    event.preventDefault();

    dispatch(userForgotPasswordActions.setIsClickedToSecondPage());

    if (newPassword === confirmPassword) {
      dispatch(changePassword(passwordInformation));
      dispatch(userForgotPasswordActions.setIsClickedToSecondPage());
    } else {
      toast.error("Passwords do not match.", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  useEffect(() => {
    if (
      !changeIsSuccessful &&
      isClickedToSecondPage &&
      changeStatus === "succeeded"
    ) {
      toast.error("The code you entered is wrong. Please try again!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      dispatch(userForgotPasswordActions.setIsClickedToSecondPage());
    } else if (changeIsSuccessful && changeStatus === "succeeded") {
      dispatch(userForgotPasswordActions.setResetStatus());
      navigate("/signIn");
    }
  }, [changeStatus]);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch", background: "white" },
      }}
      noValidate
      autoComplete="off"
      className={styles.form}
    >
      {changeStatus === "pending" ? (
        <CircularProgress />
      ) : (
        <div className={styles.form}>
          <div className={styles.changePassword}>
            <div>
              <InputLabel
                style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }}
                htmlFor="signInEmail"
              >
                New Password
              </InputLabel>
              <TextField
                style={{ width: `${15.5}vw`, backgroundColor: "#E8F0FE" }}
                id="newPassword"
                name="newPassword"
                size="small"
                autoFocus
                type={eyeIsClicked ? "text" : "password"}
                onChange={(e) => {
                  dispatch(
                    userForgotPasswordActions.setPassword(e.target.value)
                  );
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={eyeIsClicked ? closeEye : openEye}
                        className={styles.passwordEye}
                        onClick={() => {
                          dispatch(userForgotPasswordActions.setEyeIsClicked());
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <InputLabel
                style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }}
                htmlFor="signInEmail"
              >
                Confirm Password
              </InputLabel>
              <TextField
                style={{ width: `${15.5}vw`, backgroundColor: "#E8F0FE" }}
                id="confirmPassword"
                name="confirmPassword"
                size="small"
                type={eyeIsClicked ? "text" : "password"}
                onChange={(e) => {
                  dispatch(
                    userForgotPasswordActions.setConfirmPassword(e.target.value)
                  );
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={eyeIsClicked ? closeEye : openEye}
                        className={styles.passwordEye}
                        onClick={() => {
                          dispatch(userForgotPasswordActions.setEyeIsClicked());
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <InputLabel
                style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }}
                htmlFor="signInEmail"
              >
                Code
              </InputLabel>
              <TextField
                style={{ width: `${15.5}vw`, backgroundColor: "#E8F0FE" }}
                id="code"
                name="code"
                size="small"
                onChange={(e) => {
                  dispatch(userForgotPasswordActions.setCode(e.target.value));
                }}
              />
            </div>
          </div>
          <button
            className={styles.btn_signUp}
            onClick={changePasswordHandler}
            type="submit"
          >
            CHANGE PASSWORD
          </button>
        </div>
      )}
    </Box>
  );
}
