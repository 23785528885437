import { configureStore } from "@reduxjs/toolkit";

import signUpSlice from "../redux/SignUp/signUpSlice";
import signInSlice from "../redux/SignIn/signInSlice";
import userInfoSlice from "../redux/User/userInfoSlice";
import userCalorieInfoSlice from "../redux/User/userCalorieInfoSlice";
import userCalorieGraphSlice from "../redux/User/userCalorieGraphSlice";
import userToolsSlice from "../redux/User/userToolsSlice";
import userAddingFoodSlice from "../redux/User/userAddingFoodSlice";
import userGettingFoodSlice from "../redux/User/userGettingFoodSlice";
import userGettingActivitySlice from "../redux/User/userGettingActivitySlice";
import userAddingActivitySlice from "../redux/User/userAddingActivitySlice";
import userCalorieHistorySlice from "../redux/User/userCalorieHistorySlice";
import userForgotPasswordSlice from "../redux/User/userForgotPasswordSlice";
import userFoodCalorieCalculatorSlice from "../redux/User/userFoodCalorieCalculatorSlice";
import userActivityCalorieCalculatorSlice from "../redux/User/userActivityCalorieCalculatorSlice";
import userCaloriePredictorSlice from "../redux/User/userCaloriePredictorSlice";
import userAddGoalSlice from "../redux/User/userAddGoalSlice";

export const store = configureStore({
  reducer: {
    signUp: signUpSlice,
    signIn: signInSlice,
    userInfo: userInfoSlice,
    userCalorieInfo: userCalorieInfoSlice,
    userCalorieGraph: userCalorieGraphSlice,
    userTools: userToolsSlice,
    userAddingFood: userAddingFoodSlice,
    userGettingFood: userGettingFoodSlice,
    userGettingActivity: userGettingActivitySlice,
    userAddingActivity: userAddingActivitySlice,
    userCalorieHistory: userCalorieHistorySlice,
    userForgotPassword: userForgotPasswordSlice,
    userFoodCalculator: userFoodCalorieCalculatorSlice,
    userActivityCalculator: userActivityCalorieCalculatorSlice,
    userCaloriePredictor: userCaloriePredictorSlice,
    userAddGoal: userAddGoalSlice,
  },
});
