import dot from "../../../../../assets/images/Pages/ProfilePage/dot.png";
import plusIcon from "../../../../../assets/images/Pages/ProfilePage/plus.png";

import { useDispatch, useSelector } from "react-redux";
import { userAddingFoodActions } from "../../../../../redux/User/userAddingFoodSlice";
import {
  getFoodCategories,
  getFoods,
} from "../../../../../redux/User/userGettingFoodSlice";

import styles from "./HistoryFoodTable.module.css";

import TruncatedTextWithTooltip from "../../../../TruncatedTextWithTooltip/TruncatedTextWithTooltip";
import { useEffect } from "react";

export default function HistoryFoodTable({ list }) {
  const dispatch = useDispatch();
  const nullFilteredData = useSelector(
    (state) => state.userGettingFood.nullFilteredData
  );
  const token = useSelector((state) => state.signIn.token);

  return (
    <div className={styles.main}>
      <div className={styles.mainTop}>
        <div className={styles.tableName}>
          <div className={styles.name}>
            <img src={dot} />
            <div>Consumed Foods</div>
          </div>
          <div className={styles.amount}>{list ? list.length : 0}</div>
        </div>
        <div key={Math.random()} className={styles.items}>
          {token &&
            list &&
            list.map((value) => (
              <div key={Math.random()} className={styles.item}>
                <TruncatedTextWithTooltip
                  text={value.foodDto.name}
                  className={styles.itemName}
                />
                <div className={styles.itemAmount}>{value.mass} gr</div>
                <div className={styles.itemCalories}>{value.calories} cal</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
