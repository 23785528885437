import Logo from "../../Logo/Logo";
import Buttons from "../Buttons/Buttons";
import styles from "./ProfileAside.module.css";

export default function ProfileAside() {
  return (
    <aside className={styles.aside}>
      <div className={styles.logo}>
        <Logo width={8} />
      </div>
      <div className={styles.buttons}>
        <Buttons />
      </div>
      <div className={styles.copyRight}>@2024 EFP</div>
    </aside>
  );
}
