import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { signIn, signInActions } from "../../../redux/SignIn/signInSlice";
import { getUserGraphs } from "../../../redux/User/userCalorieGraphSlice";
import { getUserCalorieInfo } from "../../../redux/User/userCalorieInfoSlice";
import { getUserInfo } from "../../../redux/User/userInfoSlice";
import { toast } from "react-toastify";

import {
  Box,
  CircularProgress,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";

import openEye from "../../../assets/images/Pages/SignPages/openEye.png";
import closeEye from "../../../assets/images/Pages/SignPages/closeEye.png";

import styles from "./SignInForm.module.css";
import { userToolsActions } from "../../../redux/User/userToolsSlice";

export default function SignInForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const status = useSelector((state) => state.signIn.status);
  const token = useSelector((state) => state.signIn.token);
  const information = useSelector((state) => state.signIn.userInformation);

  const eyeIsClicked = useSelector((state) => state.signIn.eyeIsClicked);

  const email = useSelector((state) => state.signIn.userInformation.email);
  const password = useSelector(
    (state) => state.signIn.userInformation.password
  );

  const signInHandler = (event) => {
    event.preventDefault();
    dispatch(signInActions.setIsClickedToSignInButton());
    if (email.trim() !== "" && password.trim() !== "") {
      dispatch(signIn(information));
      dispatch(userToolsActions.setActiveButton("Panel"))
    } else {
      toast.error("Please fill in all required fields to continue.", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const toForgotLink = () => {
    navigate("/forgotPasswordFirstPage");
  };

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
      dispatch(getUserCalorieInfo());
      dispatch(getUserGraphs());
      navigate("/profile");
    }
  }, [token]);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch", background: "white" },
      }}
      noValidate
      autoComplete="off"
      className={styles.form}
    >
      {status === "pending" ? (
        <CircularProgress />
      ) : (
        <div className={styles.signInContainer}>
          <div>
            <div className={styles.label}>
              <InputLabel
                style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }}
                htmlFor="signInEmail"
              >
                Email Address
              </InputLabel>
            </div>
            <TextField
              style={{ width: `${15.5}vw`, backgroundColor: "#E8F0FE" }}
              id="signInEmail"
              name="signInEmail"
              size="small"
              type="email"
              onChange={(e) => dispatch(signInActions.setEmail(e.target.value))}
              autoFocus
            />
          </div>

          <div>
            <div className={styles.label}>
              <InputLabel
                style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }}
                htmlFor="signInPassword"
              >
                Password
              </InputLabel>
              <div onClick={toForgotLink} className={styles.forgotLink}>
                Forgot Password
              </div>
            </div>
            <TextField
              style={{ width: `${15.5}vw`, backgroundColor: "#E8F0FE" }}
              id="signInPassword"
              name="signInPassword"
              size="small"
              type={eyeIsClicked ? "text" : "password"}
              onChange={(e) =>
                dispatch(signInActions.setPassword(e.target.value))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={eyeIsClicked ? closeEye : openEye}
                      className={styles.passwordEye}
                      onClick={() => {
                        dispatch(signInActions.setEyeIsClicked());
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <button className={styles.btn_signIn} onClick={signInHandler}>
            SIGN IN
          </button>
        </div>
      )}
    </Box>
  );
}
