import { Box, CircularProgress, InputLabel, TextField, css } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  sendEmail,
  userForgotPasswordActions,
} from "../../../redux/User/userForgotPasswordSlice";

import { toast } from "react-toastify";

import styles from "./ForgotPasswordFirstPageForm.module.css";

export default function ForgotPasswordFirstPageForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isClickedToFirstPage = useSelector(
    (state) => state.userForgotPassword.isClickedToFirstPage
  );

  const email = useSelector((state) => state.userForgotPassword.codeEmail);
  const emailStatus = useSelector(
    (state) => state.userForgotPassword.emailStatus
  );
  const emailIsSuccessful = useSelector(
    (state) => state.userForgotPassword.emailIsSuccessful
  );

  const finishFirstPart = (event) => {
    event.preventDefault();
    dispatch(userForgotPasswordActions.setIsClickedToFirstPage());
    dispatch(sendEmail(email));
  };

  useEffect(() => {
    if (!emailIsSuccessful && isClickedToFirstPage && emailStatus === "succeeded") {
      toast.error("Email is not found!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      dispatch(userForgotPasswordActions.setIsClickedToFirstPage());
    } else if (emailIsSuccessful && emailStatus === "succeeded") {
      navigate("/forgotPasswordSecondPage");
    }
  }, [emailStatus]);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch", background: "white" },
      }}
      noValidate
      autoComplete="off"
      className={styles.form}
    >
      {emailStatus === "pending" ? (
        <CircularProgress />
      ) : (
        <div className={styles.form}>
          <div className={styles.info}>
            Please enter your email. We will send a code to help you to change
            your password.
          </div>
          <div className={styles.mailPart}>
            <InputLabel
              style={{ color: "black", marginLeft: `${.5}vw`, fontSize: `${2.37875}vmin` }}
              htmlFor="signInEmail"
            >
              Email
            </InputLabel>
            <TextField
              style={{ width: `${15.5}vw`, backgroundColor: "#E8F0FE" }}
              id="emailPassword"
              name="emailPassword"
              size="small"
              type="email"
              autoFocus
              onChange={(e) => {
                dispatch(userForgotPasswordActions.setEmail(e.target.value));
                dispatch(
                  userForgotPasswordActions.setCodeEmail(e.target.value)
                );
              }}
            />
          </div>

          <button
            className={styles.btn_signUp}
            onClick={finishFirstPart}
            type="submit"
          >
            SEND CODE
          </button>
        </div>
      )}
    </Box>
  );
}
