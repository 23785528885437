import { useNavigate } from "react-router-dom";

import Image1 from "../../../assets/images/Pages/IntroductionPage/Image1.jpg";
import Image6 from "../../../assets/images/Pages/IntroductionPage/Image6.png";
import arrow from "../../../assets/images/Pages/IntroductionPage/left-arrow.png";
import firework from "../../../assets/images/Pages/IntroductionPage/fireworks.png";

import styles from "./IntroductionPartUp.module.css";

export default function IntroductionPartUp() {
  const navigate = useNavigate();

  const goToSignUp = (event) => {
    event.preventDefault();
    navigate("/signUpFirstPage");
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        Track, Balance, and Achieve Your Goals!
      </div>
      <div className={styles.content}>
        <div className={styles.signUpPart}>
          <img src={Image1} className={styles.image1} />
          <div className={styles.signUpText}>You can sign up for free!</div>
          <button className={styles.signUpButton} onClick={goToSignUp}>
            SIGN UP
          </button>
        </div>
        <div className={styles.paragraphContainer}>
          <div className={styles.paragraph}>
            Welcome to Eat Fit Pro, your ultimate companion for mastering the
            art of calorie management! Whether you're looking to lose weight,
            gain muscle, or simply maintain a healthy lifestyle, our app is
            designed to make your journey smoother and more efficient. With Eat
            Fit Pro, you can effortlessly track your daily calorie intake and
            the calories burned through various activities. Our user-friendly
            interface provides real-time insights into your nutritional habits,
            helping you make informed choices about what you eat and how you
            exercise. Join our community of fitness enthusiasts and embark on a
            path to a healthier, more balanced life with Eat Fit Pro by your
            side. Start your transformative journey today!
          </div>
        </div>
        <div className={styles.gymPhoto}>
          <img src={Image6} className={styles.image2} />
        </div>
      </div>
      <div className={styles.icon}>
        <div className={styles.upCircle}></div>
        <div className={styles.connection}></div>
        <div className={styles.downCircle}></div>
      </div>
      <img src={arrow} className={`${styles.arrow} ${styles.paragraphIcon}`} />
      <img
        src={firework}
        className={`${styles.firework} ${styles.paragraphIcon}`}
      />
    </div>
  );
}
