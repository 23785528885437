import React, { useEffect } from "react";
import styles from "./UserInfo.module.css";

import ageIcon from "../../../assets/images/Pages/ProfilePage/age2.png";
import heightIcon from "../../../assets/images/Pages/ProfilePage/height.png";
import weightIcon from "../../../assets/images/Pages/ProfilePage/weight.png";

export default function UserInfo({ age, height, weight }) {
  return (
    <div className={styles.userInformation}>
      <div className={styles.iconContainer}>
        <img src={ageIcon} />
        <div className={styles.age}>Age:&nbsp;{age}</div>
      </div>
      <div className={styles.iconContainer}>
        <img src={heightIcon} />
        <div className={styles.height}>Height: {height} cm</div>
      </div>
      <div className={styles.iconContainer}>
        <img src={weightIcon} />
        <div className={styles.weight}>Weight: {weight} kg</div>
      </div>
    </div>
  );
}
