import { Autocomplete, TextField, CircularProgress, makeStyles } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  foodCalculator,
  getFoodsCalculator,
  userFoodCalorieCalculatorActions,
} from "../../../../redux/User/userFoodCalorieCalculatorSlice";

import styles from "./FoodCalculatorTable.module.css";
import searchIcon from "../../../../assets/images/Pages/ProfilePage/search.png";
import FoodCalculatorPagination from "../../TablePagination/FoodCalculatorPagination";
import { toast } from "react-toastify";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 180,
    sortable: false,
    filterable: false,
  },
  {
    field: "calories",
    headerName: "Calories ( per 100gr )",
    width: 160,
    sortable: false,
    filterable: false,
  },
  {
    field: "foodCategoryDto",
    headerName: "Category",
    width: 170,
    sortable: false,
    filterable: false,
    valueGetter: (params) => params.row?.foodCategoryDto?.name,
  },
];

export default function FoodCalculatorTable() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.signIn.token);
  const filteredData = useSelector(
    (state) => state.userFoodCalculator.filteredData
  );
  const pageNumber = useSelector(
    (state) => state.userFoodCalculator.pageNumber
  );
  const totalPage = useSelector((state) => state.userFoodCalculator.totalPage);
  const foodCategories = useSelector(
    (state) => state.userFoodCalculator.foodCategories
  );
  const foods = useSelector((state) => state.userFoodCalculator.foods);
  const category = useSelector(
    (state) => state.userFoodCalculator.filteredData.category
  );
  const foodRecord = useSelector(
    (state) => state.userFoodCalculator.foodRecord
  );
  const calculationResult = useSelector(
    (state) => state.userFoodCalculator.calculationResult
  );

  const mass = useSelector((state) => state.userFoodCalculator.foodRecord.mass);
  const id = useSelector(
    (state) => state.userFoodCalculator.foodRecord.foodDto.id
  );

  const calculateHandler = (event) => {
    event.preventDefault();
    if (mass && id && mass > 0) {
      dispatch(foodCalculator(foodRecord));
      dispatch(userFoodCalorieCalculatorActions.setRefresh());
    } else {
      toast.error(
        "You must fill the amount area and choose a food from the table to calculate. You can't enter negative value.",
        {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  return (
    <Box className={styles.main}>
      <Box component="form" autoComplete="off">
        <div
          id="transition-modal-title"
          variant="h6"
          component="h2"
          className={styles.filterPart}
        >
          <Autocomplete
            getOptionLabel={(option) => option.name}
            clearOnEscape
            options={foodCategories}
            size="small"
            value={category}
            renderInput={(params) => (
              <TextField {...params} label="All Categories" />
            )}
            onChange={(event, value) => {
              if (value) {
                dispatch(userFoodCalorieCalculatorActions.setCategory(value));
                dispatch(
                  userFoodCalorieCalculatorActions.setFoodCategoryName(
                    value.name
                  )
                );
              } else {
                dispatch(userFoodCalorieCalculatorActions.setCategory(null));
                dispatch(
                  userFoodCalorieCalculatorActions.setFoodCategoryName("")
                );
              }
            }}
            className={styles.categories}
          />
          <TextField
            label="Name"
            sx={{"& input::placeholder": { fontSize: "55px"},}}
            size="small"
            onChange={(e) =>
              dispatch(userFoodCalorieCalculatorActions.setName(e.target.value))
            }
            className={styles.name}
          />
          <button
            onClick={(event) => {
              event.preventDefault();
              dispatch(
                getFoodsCalculator({ filteredData: filteredData, page: 1 })
              );
              dispatch(userFoodCalorieCalculatorActions.setPageNumber(1));
            }}
            className={styles.filterButton}
            type="submit"
          >
            <div>FIND</div>
            <img src={searchIcon} />
          </button>
        </div>
      </Box>
      <Box
        component="form"
        autoComplete="off"
        className={styles.calculateContainer}
      >
        <div id="transition-modal-description" sx={{ mt: 2 }}>
          <DataGrid
            rows={foods}
            columns={columns}
            hideFooter
            style={{
              maxHeight: `${39.6}vh`,
              minHeight: `${39.6}vh`,
              width: `${35.2}vw`,
              marginTop: `${1.47}vw`,
              fontSize: "1.94625vmin",
            }}
            onRowClick={(params) => {
              dispatch(
                userFoodCalorieCalculatorActions.setFoodCalculatorId(
                  params.row.id
                )
              );
            }}
          />
          <FoodCalculatorPagination
            pageNumber={pageNumber}
            totalPage={totalPage}
          />
        </div>
        <div
          variant="h6"
          component="h2"
          sx={{ mt: 2 }}
          className={styles.calculatePart}
        >
          <TextField
            label="Amount (gr)"
            size="small"
            onChange={(e) =>
              dispatch(
                userFoodCalorieCalculatorActions.setFoodCalculatorMass(
                  e.target.value
                )
              )
            }
            className={styles.amount}
          />
          <button
            onClick={calculateHandler}
            className={styles.calculateButton}
            type="submit"
          >
            Calculate
          </button>
        </div>
      </Box>

      <div className={styles.calculationResult}>
        {calculationResult} Calories
      </div>
    </Box>
  );
}
