import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  getFoods,
  userGettingFoodActions,
} from "../../../redux/User/userGettingFoodSlice";

const TablePagination = ({ pageNumber, totalPage }) => {
  const dispatch = useDispatch();
  const filteredData = useSelector(
    (state) => state.userGettingFood.filteredData
  );

  return (
    <Stack spacing={2} style={{ marginTop: "3vh" }}>
      <Pagination
        count={totalPage}
        color="primary"
        page={pageNumber}
        onChange={(event, page) => {
          if (page !== pageNumber) {
            dispatch(userGettingFoodActions.setPageNumber(page));
            dispatch(getFoods({ filteredData: filteredData, page: page }));
          }
        }}
      />
    </Stack>
  );
};

export default TablePagination;
