import Logo from "../../Logo/Logo";

import styles from "./Footer.module.css";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.leftPart}>
        <div className={styles.logo}>
          <Logo width={6} />
        </div>
        <div className={styles.copyright}>© 2024 EFP Terms</div>
      </div>
      <div className={styles.middlePart}>
        <div className={styles.toolsTitle}>Tools</div>
        <a href="#">Burned Calories Calculator</a>
        <a href="#">Consumed Calories Calculator</a>
        <a href="#">Forecasting Tool</a>
      </div>
      <div className={styles.rightPart}>
        <div className={styles.aboutUsTitle}>About Us</div>
        <a href="#">Developers</a>
        <a href="#">Our Journey</a>
        <a href="#">The Tech Stack</a>
      </div>
    </footer>
  );
}
