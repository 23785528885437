import { Box, CircularProgress, InputLabel, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  signUpActivate,
  signUpActions,
} from "../../../redux/SignUp/signUpSlice";
import styles from "./SignUpVerifyForm.module.css";
import { toast } from "react-toastify";

export default function SignUpVerifyForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const verifyStatus = useSelector((state) => state.signUp.verifyStatus);
  const verifyInformation = useSelector(
    (state) => state.signUp.verifyInformation
  );

  const codeArray = useSelector((state) => state.signUp.codeArray);
  const userEmail = useSelector((state) => state.signUp.userInformation.email);

  const isClickedToVerifyButton = useSelector(
    (state) => state.signUp.isClickedToVerifyButton
  );

  const isVerifySuccessful = useSelector(
    (state) => state.signUp.isVerifySuccessful
  );

  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    if (/^\d*$/.test(value)) {
      dispatch(signUpActions.setDigit({ index, value }));

      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      } else if (value.length >= 6) {
        const digits = value.slice(0, 6).split("");
        digits.forEach((digit, idx) => {
          dispatch(signUpActions.setDigit({ index: idx, value: digit }));
        });
      }
    }
  };

  useEffect(() => {
    const handlePaste = (event) => {
      event.preventDefault()
      const pastedText = event.clipboardData.getData("Text");
      if (pastedText.length >= 6) {
        dispatch(signUpActions.setResetCodeArray());

        const digits = pastedText.slice(0, 6).split("");
        digits.forEach((digit, idx) => {
          dispatch(signUpActions.setDigit({ index: idx, value: digit }));
        });
      }
    };

    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!codeArray[index] || codeArray[index].length === 1) {
        e.preventDefault();

        if (codeArray[index].length === 1) {
          dispatch(signUpActions.setDigit({ index, value: "" }));
        }

        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      }
    }
  };

  const finishSignUp = (event) => {
    event.preventDefault()
    const allBoxesFilled = codeArray.every((code) => code.trim() !== "");

    if (allBoxesFilled) {
      const verificationCode = codeArray.join("");
      dispatch(signUpActions.setCode(verificationCode));
      dispatch(signUpActions.setVerifyMail(userEmail));
    } else {
      alert("Please fill in all the boxes before verifying.");
    }

    dispatch(signUpActions.setIsClickedToVerifyButton());
  };

  useEffect(() => {
    if (isClickedToVerifyButton) {
      dispatch(signUpActivate(verifyInformation));
    }
  }, [isClickedToVerifyButton]);

  useEffect(() => {
    if (
      !isVerifySuccessful &&
      isClickedToVerifyButton &&
      verifyStatus === "succeeded"
    ) {
      toast.error("The code you entered is wrong. Please try again!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      dispatch(signUpActions.setIsClickedToVerifyButton());
    } else if (isVerifySuccessful && verifyStatus === "succeeded") {
      dispatch(signUpActions.setResetCodeArray());
      dispatch(signUpActions.setResetStatus());
      navigate("/signIn");
    }
  }, [verifyStatus]);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch", background: "white" },
      }}
      noValidate
      autoComplete="off"
      className={styles.form}
    >
      {verifyStatus === "pending" ? (
        <CircularProgress />
      ) : (
        <div className={styles.form}>
          <div className={styles.info}>
            Please check your email for the verification code we've sent you.
            Enter the code in the field below to complete your sign-up process.
          </div>
          <div className={styles.code}>
            {Array.from({ length: 6 }).map((_, index) => (
              <TextField
                key={index}
                style={{ width: `${2.5}vw`, margin: `${.3}vw`, padding: 0 }}
                inputRef={(el) => (inputRefs.current[index] = el)}
                type="tel"
                id={`code-${index}`}
                name={`code-${index}`}
                size="small"
                inputProps={{ maxLength: 1, style: {fontSize: `${2.37875}vmin`, margin: 0, padding: "1.125vh .95vw"}
                }}
                value={codeArray[index]}
                autoFocus={index === 0 ? true : false}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
          </div>
          <button
            className={styles.btn_signUp}
            onClick={finishSignUp}
            type="submit"
          >
            FINISH
          </button>
        </div>
      )}
    </Box>
  );
}
